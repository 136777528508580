<template>
	<div class="common-layout">
		<el-container>
			<el-aside v-if="$route.path!='/calculator'"  width="270px">
				<sidebar></sidebar>
			</el-aside>
			<el-container class="right" :style="{'border-radius':$route.path=='/calculator'?'0':'20px 0 0 20px'}">
				<el-header>
					<navs></navs>
				</el-header>
				<el-main :style="{'border-radius':$route.path=='/statistic/financial_reports'?'0':'48px'}">
					<router-view />
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	import navs from './navs.vue'
	import sidebar from './sidebar.vue'
	export default {
		name: 'layout',
		data() {
			return {
				height: '',
				width: '',
			}
		},
		watch: {
		},
		created() {},
		mounted() {
			// this.height = window.outerHeight
			// this.width = window.screen.width
			// let l = document.getElementsByClassName('layout')[0]
			// l.style.minWidth = this.width + "px"
			// l.style.minHeight = this.height + "px"
			// console.log(window)
		},
		components: {
			navs,
			sidebar
		}
	}
</script>

<style scoped>
	.common-layout {
		width: 100vw;
		height: 100vh;
		background-color: var(--background-light);
		min-width: 1280px;
	}

	.common-layout::-webkit-scrollbar {
		display: none;
	}

	.common-layout .el-aside {
		width: 20%;
		min-width: 270px;
		height: 100vh;
	}

	.common-layout .el-aside {
		width: 20%;
		min-width: 270px;
		height: 100vh;
	}

	.common-layout .right {
		background-color: var(--background-color);
		border-radius: 20px 0 0 20px;
		height: 100vh;
	}
	.common-layout .right .el-header{
		height: 100px;
	}
	.common-layout .right .el-main {
		background-color: var(--background-light);
		border-radius: 48px;
		margin:0 20px 20px 20px;
	}

</style>
<template>
    <div class="box">
        <div class="top">
            <ul class='left'>
            </ul>
            <div class="right">
                <button @click="openPop(1)">New promocode</button>
            </div>
        </div>
        <div class="con-table">
            <table>
                <thead>
                    <tr>
                        <th style="width: 5%;">№</th>
                        <th style="width: 15%;">Promocode</th>
                        <th style="width: 16%;">Discount percentage</th>
                        <th style="width: 15%;">Validity</th>
                        <th style="width: 15%;">Number of uses</th>
                        <th style="width: 12%;" class="icon_box">
                            Date
                            <!-- <div class="icon icon_down" :style="{'transform': sort_data==2?'rotateX(180deg)':''}"
								@click="sort_data=sort_data==1?2:1"></div> -->
                        </th>
                        <th style="width: 12%;">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.percentage }}%</td>
                        <td>{{ getDate(item.start*1000) }} - {{ getDate(item.end*1000) }}</td>
                        <td>{{ item.use }}</td>
                        <td>{{ getDate(item.created_at*1000) }}</td>
                        <td class="icon_box">
                            <div class="icon icon_del" @click="openPop_del(item)"></div>
                            <div class="icon icon_edit" @click="openPop(2, item)"></div>
                        </td>

                    </tr>
                </tbody>
            </table>

        </div>
        <div class="pop-bg" v-if="bg" @click="close()">

            <div class="pop_box" v-if="pop_del" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Delete Promocode
                    </div>
                    <div class="text-gray left">
                        Are you sure you want to delete the Promocode?
                    </div>
                    <div class="inp-box">
                        <textarea v-model="comment"></textarea>
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">No</button>
                        <button class="submit" @click="del">Yes</button>
                    </div>
                </div>

            </div>
            <div class="pop_box" v-if="pop_create" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        {{ popType == 1 ? 'New Promocode' : 'Edit Promocode ' }}
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="name">Name</div>
                        <input v-model="name" placeholder="Name" :style="{ 'margin-top': name ? '5px' : '20px' }" />
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="percentage">Discount percentage(%)</div>
                        <input type="number" v-model="percentage" placeholder="Discount percentage"
                            :style="{ 'margin-top': percentage ? '5px' : '20px' }" />
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="date">Period</div>
                        <div class="date-box">
                            <span v-if="date">{{ date }}</span>
                            <span v-else style="opacity: 0.4;">Period</span>
                            <div class="icon icon_date">
                                <el-date-picker class="date-picker" v-model="date" type="daterange"
                                    :disabled-date="disabledDate" @change="changeDate" />
                            </div>
                        </div>
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">Cancel</button>
                        <button class="submit" @click="save">Save</button>
                    </div>
                </div>

            </div>
        </div>
        <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
            @backPage='backPage'></pagination>
    </div>
</template>

<script>
import {
    ElMessage
} from 'element-plus'
import Api from '../../utils/api.js';
import pagination from '../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            search: '',
            pageSize: 8,
            nowPage: 1,
            total: 0,
            list: [],
            bg: false,
            pop_del: false,
            pop_create: false,
            popType: 1,
            comment: '',
            sort_data: 1,
            percentage: '',
            name: '',
            id: "",
            date: '',
            start:'',
            end:''
        }

    },
    watch: {
        sort_data(e) {
            this.getList();
        }
    },
    created() {

    },
    mounted() {
        let table = document.getElementsByClassName('con-table')[0];
        this.pageSize = parseInt((table.clientHeight - 50) / 50);
        this.getList();
    },
    methods: {
        changeDate(e) {
            if (e) {
                this.start =e[0]; 
                this.end =e[1]; 
                this.date = this.getDate(e[0]) + ' - ' + this.getDate(e[1]);
            }

        },
        disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
        },
        openPop(e, item) {
            this.popType = e
            this.pop_create = true;
            this.bg = true;
            if (item) {
                this.id = item.id;
                this.name = item.name;
                this.percentage = item.percentage;
                this.date = this.getDate(item.start*1000)+ ' - '+this.getDate(item.end*1000);
                this.start = new Date(item.start*1000);
                this.end = new Date(item.end*1000);
            }
        },
        openPop_del(item) {
            this.bg = true;
            this.pop_del = true;
            this.id = item.id;
        },
        close() {
            this.pop_del = false;
            this.pop_create = false;
            this.bg = false;
            this.name = '';
            this.date = '';
            this.id = '';
            this.comment = '';
            this.start = '';
            this.end = '';
        },
        save() {
            if (this.name == '' || this.percentage == '' || this.date == '') {
                ElMessage('Please complete the information.');
                return;
            }
            if(this.percentage<1||this.percentage>100){
                ElMessage('Percentage range 0~100');
                return;
            }
            if (this.popType == 1) {
                Api.promoCodeCreate({
                    name: this.name,
                    percentage: this.percentage,
                    start:this.start.getTime()/1000,
                    end:this.end.getTime()/1000
                }).then(res => {
                    if (res.code == 200) {
                        this.getList();
                        this.close();
                    } else {
                        ElMessage.error(res.message)
                    }
                })
            } else {
                Api.promoCodeEdit({
                    promo_code_id: this.id,
                    name: this.name,
                    percentage: this.percentage,
                    start:this.start.getTime()/1000,
                    end:this.end.getTime()/1000
                }).then(res => {
                    if (res.code == 200) {
                        this.getList();
                        this.close();
                    } else {
                        ElMessage.error(res.message)
                    }
                })
            }

        },
        del() {
            if (this.comment == '') {
                ElMessage('Please complete the comment.');
                return;
            }
            Api.promoCodeDelete({
                promo_code_id: this.id,
                comment: this.comment
            }).then(res => {
                if (res.code == 200) {
                    this.getList();
                    this.close();
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        getDate(e) {
            const date = new Date(e);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${day}.${month}.${year}`
        },
        goSearch() {
            this.nowPage = 1;
            this.getList();
        },
        change_booster(e, item, index) {
            console.log(e, item);
            item.booster_name_choose = e;
            this.choose_booster_index = index;
        },
        getList() {
            Api.promoCodeList({
                limit: this.pageSize,
                search: this.search,
                sort: this.sort_data,
                page: this.nowPage
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data.data;
                    this.total = res.data.meta.pagination.total;
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        goPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        nextPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        backPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
    }
}
</script>
<style>
.el-picker-panel__icon-btn{
    color: #fff !important;
}
</style>
<style scoped>
.box {
    width: 100%;
    height: 100%;
    position: relative;
}

.con-table {
    height: calc(100% - 106px);
}

.top .left li {
    width: auto;
}

.con-table table tr .icon_box.submit .icon {
    cursor: no-drop !important;
}
</style>
<template>
    <div class="box">
        <div class="top">
            <ul class='left'>
                <li>
                    <span>Support</span>
                    <div class="num">{{ total }}</div>
                </li>
            </ul>

            <div class="right">
                <div class="search_box">
                    <input v-model="search" placeholder="Search by keywords" @keyup.enter="goSearch">
                    <div class="icon icon_search" @click="goSearch"></div>
                </div>
                <!-- <button @click="openPop(1)">Add new</button> -->
            </div>
        </div>
        <div class="con-table">
            <table>
                <thead>
                    <tr>
                        <th style="width: 12%;">Info</th>
                        <th style="width: 20%;">Name</th>
                        <th style="width: 21%;">Email</th>
                        <!-- <th style="width: 15%;">Question</th> -->
                        <th style="width: 20%;" class="icon_box">
                            Registration Date
                            <div class="icon icon_down" :style="{ 'transform': sort_data == 2 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 1 ? 2 : 1"></div>
                        </th>
                        <th style="width: 17%;">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td class="icon_box">
                            <div class="icon icon_info"></div>
                        </td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <!-- <td>{{ item.question }}</td> -->
                        <td>{{ getDate(item.created_at) }}</td>
                        <!-- <td class="txt_box" v-if="item.status == 1">
                            <div class="green_txt">
                                Completed
                            </div>
                        </td> -->
                        <td class="icon_box" >
                            <!-- <el-switch v-model="item.status" class="ml-2" @change="changeStatus(item)" :active-value="0"
                                :inactive-value="1"
                                style="--el-switch-on-color: var(--submit); --el-switch-off-color:var(--background-light)" /> -->
                            <!-- <div class="icon icon_yes" @click="changeStatus(item)"></div> -->
                            <div v-if="item.unread<=0" class="icon icon_chat" @click="goChat(item)"></div>
                            <div v-else  class="icon icon_chat_pink" @click="goChat(item)"></div>
                        </td>

                    </tr>
                </tbody>
            </table>

        </div>

        <div class="pop-bg" v-if="bg" @click="close()">
            <div class="pop_box" v-if="pop" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Complete support
                    </div>
                    <div class="text-gray left">
                        Please confirm this support has been completed.
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">No</button>
                        <button class="submit" @click="yes">Yes</button>
                    </div>
                </div>

            </div>
        </div>
        <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
            @backPage='backPage'></pagination>
    </div>
</template>

<script>
import {
    ElMessage
} from 'element-plus'
import Api from '../../utils/api.js';
import pagination from '../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            search: '',
            pageSize: 8,
            nowPage: 1,
            total: 0,
            list: [],
            bg: false,
            pop: false,
            sort_data: 1,

        }

    },
    watch: {
        sort_data(e) {
            this.getList();
        }
    },
    created() {

    },
    mounted() {
        let table = document.getElementsByClassName('con-table')[0];
        this.pageSize = parseInt((table.clientHeight - 50) / 50);
        this.getList();
    },
    methods: {
        goChat(item) {
            this.$router.push({
                name: 'support_chat',
                query: {
                    other: JSON.stringify(item),
                    folder: 'support'
                }
            })
        },
        changeStatus(item) {
            this.bg = true;
            this.pop = true;
            this.id = item.id;

        },
        yes() {
            Api.supportStatus({
                support_id: this.id
            }).then(res => {
                if (res) {
                    if (res.code == 200) {
                        this.close();
                        this.getList();
                    } else {
                        ElMessage.error(res.message);
                    }
                }
            })
        },
        close() {
            this.pop = false;
            this.bg = false;
            this.id = '';
        },
        getDate(e) {
            const date = new Date(e ? e * 1000 : '');
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${day}/${month}/${year}`
        },
        goSearch() {
            this.nowPage = 1;
            this.getList();
        },
        getList() {
            Api.supportList({
                limit: this.pageSize,
                search: this.search,
                sort: this.sort_data,
                page: this.nowPage
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data.data;
                    this.total = res.data.meta.pagination.total;
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        goPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        nextPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        backPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
    }
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 100%;
    position: relative;
}

.con-table {
    height: calc(100% - 106px);
}

.top .left li {
    width: auto;
}

.con-table table tr .icon_box.submit .icon {
    cursor: no-drop !important;
}
</style>
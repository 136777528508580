<template>
	<div class="box">
		<ul class="left">
			<li v-for="(item, index) in bar" :key="index" @click="barType = index + 1"
				:class="item.id == barType ? 'active' : ''">
				<div class="title">
					<img :src="item.icon" alt="">
					<span>{{ item.name }}</span>
				</div>
				<div class="icon_edit"></div>
				<el-switch v-model="item.opened" class="ml-2"
					style="--el-switch-on-color:  var(--text-color-purple); --el-switch-off-color:var(--background-light2)" />
			</li>
		</ul>
		<div class="right">
			<div class="tab">
				<!-- <div class="item">Info</div> -->
				<div class="item active">Price</div>
			</div>
			<div class="frist-box" v-if="barType == 1">
				<div class="number">
					<div class="num">1</div>
					<div class="txt">Rank</div>
				</div>
				<div class="con">
					<div :class="item.length <= 5 ? 'item-arr' : ''" v-for="(item, index) in list0" :key="index">
						<div v-if="item.length <= 5">
							<ul>
								<li v-for="(itm, idx) in item" :key="idx">
									<span>{{ itm.name }}</span>
									<input :id="'input1-' + idx" v-model="itm.money" @blur="inputBlur(itm)"
										@keyup.enter="onblur('input1', idx)"
										oninput="value=value.replace(/[^0-9.]/g,'').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
									<div class=" icon icon_yes"></div>
								</li>
							</ul>
						</div>
						<div class="item-arr" v-else v-for="(i, d) in parseInt(item.length / 5)" :key="d">
							<ul>
								<div v-for="(number, idx) in 5 * i" :key="idx">
									<li v-if="idx >= 5 * d">
										<span>{{ item[idx].name }}</span>
										<input :id="'input2-' + idx" v-model="item[idx].money"
											@blur="inputBlur(item[idx])" @keyup.enter="onblur('input2', idx)"
											oninput="value=value.replace(/[^0-9.]/g,'').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
										<div class=" icon icon_yes"></div>
									</li>
								</div>

							</ul>
						</div>

					</div>

				</div>
			</div>
			<div class="frist-box" v-if="barType == 2||barType == 3">
				<div class="number">
					<div class="num">1</div>
					<div class="txt">Price per game</div>
				</div>
				<div class="con">
					<ul class="item-arr" v-for="(itm, index) in list1" :key="index">
						<li style="margin:0">
							<span>{{ itm.name }}</span>
							<input :id="'input3-' + index" v-model="itm.money" @blur="inputBlur(itm)"
								@keyup.enter="onblur('input3', index)"
								oninput="value=value.replace(/[^0-9.]/g,'').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
							<div class=" icon icon_yes"></div>
						</li>
					</ul>
				</div>
			</div>
			<div class="second-box">
				<div class="number">
					<div class="num">2</div>
					<div class="txt">Optional Features</div>
				</div>
				<div class="con">
					<ul class="item-arr">
						<li v-for="(item, index) in featureList" :key="index">
							<span class="name">{{ item.name }}</span>
							<input type="Number" :id="'featureInput-' + index" v-model="item.increase"
								@blur="feature_inputBlur(item)" @keyup.enter="feature_onblur('featureInput', index)" />
							<span class="percent">%</span>
							<el-switch v-model="item.status" class="ml-2" :active-value="1" :inactive-value="0"
								@change="feature_switch(item)"
								style="--el-switch-on-color: var(--text-color-purple); --el-switch-off-color:var(--background-light2)" />
							<input class="txt" v-model="item.description" placeholder="Description" disabled/>
							<div class="edit-icon"  @click="openDesciptionPop(item,index)"></div>
							<el-switch v-model="item.description_status" class="ml-2" :active-value="1" :inactive-value="0"
								@change="description_switch(item)"
								style="--el-switch-on-color: var(--text-color-purple); --el-switch-off-color:var(--background-light2)" />
						</li>
					</ul>
					<div class="add" @click="addFeatures">+Add</div>

				</div>
			</div>
			<div class="third-box">
				<div class="number">
					<div class="num">3</div>
					<div class="txt">ACc Shared or Self-Play</div>
				</div>
				<div class="con">
					<ul class="item-arr">
						<li v-for="(item, index) in accList" :key="index">
							<span class="name">{{ item.name }}</span>
							<input type="Number" :id="'accInput-' + index" v-model="item.increase"
								@blur="acc_inputBlur(item)" @keyup.enter="acc_onblur('accInput', index)" />
							<span class="percent">%</span>
							<el-switch v-model="item.status" class="ml-2" :active-value="1" :inactive-value="0"
								@change="acc_switch(item)"
								style="--el-switch-on-color: var(--text-color-purple); --el-switch-off-color:var(--background-light2)" />
						</li>
					</ul>
					<!-- <div class="add" @click="addFeatures">+Add</div> -->

				</div>
			</div>

		</div>
		<div class="icon_del" @click="back"></div>
		<div class="pop-bg" v-if="bg">
			<div class="pop_box" v-if="pop_addFeatures" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						New Optional Features
					</div>
					<!-- <div class="inp-box">
						<div class="text-gray left">Language</div>
						<div class="tab-box">
							<div class="item" :class="language == 'EN' ? 'active' : ''" @click="language = 'EN'">EN</div>
							<div class="item" :class="language == 'RU' ? 'active' : ''" @click="language = 'RU'">RU</div>
						</div>
					</div> -->
					<div class="inp-box" v-if="language == 'EN'">
						<div class="text-gray left">Title</div>
						<input v-model="title_en" placeholder="Title" />
					</div>
					<!-- <div class="inp-box" v-if="language == 'RU'">
						<div class="text-gray left">Title</div>
						<input v-model="title_ru" placeholder="Title" />
					</div> -->
					<div class="button-box">
						<button class="cancel" @click="close">Cancel</button>
						<button class="submit" @click="save_features">Create</button>
					</div>
				</div>
			</div>
			<div class="pop_box" v-if="description_pop" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Edit Optional Features
					</div>
					<!-- <div class="inp-box">
						<div class="text-gray left">Language</div>
						<div class="tab-box">
							<div class="item" :class="language == 'EN' ? 'active' : ''" @click="language = 'EN'">EN</div>
							<div class="item" :class="language == 'RU' ? 'active' : ''" @click="language = 'RU'">RU</div>
						</div>
					</div> -->
					<div class="inp-box" v-if="language == 'EN'">
						<div class="text-gray left">Description</div>
						<textarea style="height: 100px" v-model="description" placeholder="Description" />
					</div>
					<!-- <div class="inp-box" v-if="language == 'RU'">
						<div class="text-gray left">Title</div>
						<input v-model="title_ru" placeholder="Title" />
					</div> -->
					<div class="button-box">
						<button class="cancel" @click="close">Cancel</button>
						<button class="submit" @click="save_featuresEdit">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Api from '../../utils/api.js';
import {
	ElMessage, ElLoading
} from 'element-plus'
export default {
	data() {
		return {
			editIndex:null,
			description:'',
			bar: [{
				id: 1,
				name: 'Rank Boosting',
				icon: require('../../assets/img/calculator/star.png'),
				opened: true,
			},
			{
				id: 2,
				name: 'Placement Matches',
				icon: require('../../assets/img/calculator/place.png'),
				opened: true,
			},
			{
				id: 3,
				name: 'Unrated Games',
				icon: require('../../assets/img/calculator/unrated.png'),
				opened: true,
			},
			{
				id: 4,
				name: 'Ranked Wins',
				icon: require('../../assets/img/calculator/rank.png'),
				opened: true,
			},
			{
				id: 5,
				name: 'Account Leveling',
				icon: require('../../assets/img/calculator/level.png'),
				opened: true,
			},
			{
				id: 6,
				name: 'Valorant coaching',
				icon: require('../../assets/img/calculator/valorant.png'),
				opened: true,
			},
			],
			barType: 1,
			list0: [],
			featureList: [],
			accList: [],
			list1: [],
			pop_addFeatures: false,
			bg: false,
			title_en: '',
			title_ru: '',
			language: "EN",
			description_pop:'',

		}
	},
	created() {
		this.getList0();
		this.getFeatureList();
		this.getAccList();
	},
	watch: {
		barType(e) {
			if (e == 1) {
				this.getList0();
			} else if (e == 2) {
				this.getList1();
			} else if(e==3){
				this.getList2();
			}
			this.getFeatureList();
			this.getAccList();
		}
	},
	methods: {
		close() {
			this.bg = false;
			this.pop_addFeatures = false;
			this.description_pop = false;
			this.description = '';
			this.editIndex = null;
			this.title_en = '';
			this.title_ru = '';
			this.language = "EN";
		},
		save_features() {
			// if (this.title_en == '' || this.title_ru == '') {
			if (this.title_en == '') {
				ElMessage('Please complete the information')
				return
			}
			Api.calculatorOptionalFeatureAdd({
				name_en: this.title_en,
				// name_ru: this.title_ru
				name_ru: this.title_en
			}).then(res => {
				if (res.code == 200) {
					this.getFeatureList();
					this.close();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		addFeatures() {
			this.pop_addFeatures = true;
			this.bg = true;
		},
		back() {
			this.$router.go(-1);
		},
		getList0() {
			Api.rankBoostingPrice().then(res => {
				if (res.code == 200) {
					this.list0 = res.data;
					this.list0.forEach(element => {
						element.forEach(element2 => {
							element2.money = parseFloat(element2.money).toFixed(2)
						})
					});

				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getAccList() {
			Api.calculatorAcc({
				calculator_id: this.barType
			}).then(res => {
				if (res.code == 200) {
					this.accList = res.data;
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getFeatureList() {
			Api.calculatorOptionalFeature({
				calculator_id: this.barType
			}).then(res => {
				if (res.code == 200) {
					this.featureList = res.data;
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		featureEdit() {
			Api.calculatorOptionalFeatureEdit({
				calculator_id: this.barType,
				data: this.featureList
			}).then(res => {
				if (res.code == 200) {
					this.getFeatureList();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		accEdit() {
			Api.calculatorAccEdit({
				calculator_id: this.barType,
				data: this.accList
			}).then(res => {
				if (res.code == 200) {
					this.getAccList();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getList1() {
			Api.placementMatchesPrice().then(res => {
				if (res.code == 200) {
					this.list1 = res.data;
					this.list1.forEach(element => {
						element.money = parseFloat(element.money).toFixed(2)
					});
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getList2() {
			Api.unratedGamesPrice().then(res => {
				if (res.code == 200) {
					this.list1 = res.data;
					this.list1.forEach(element => {
						element.money = parseFloat(element.money).toFixed(2)
					});
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		rankBoostingEdit(itm) {
			Api.rankBoostingEdit({
				id: itm.id,
				money: Number(itm.money)
			}).then(res => {
				if (res.code == 200) {
					//this.getList0();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		placementMatchesEdit(itm) {
			Api.placementMatchesEdit({
				rank_id: itm.id,
				money: Number(itm.money)
			}).then(res => {
				if (res.code == 200) {
					//this.getList1();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		unratedGamesPriceEdit(itm) {
			Api.unratedGamesPriceEdit({
				id: itm.id,
				money: Number(itm.money)
			}).then(res => {
				if (res.code == 200) {
					//this.getList1();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		onblur(e, index) {
			let name = e + '-' + index;
			let input = document.getElementById(name);
			console.log(input);
			input.blur();
		},
		inputBlur(itm) {
			// console.log('inputBlur=======')
			// console.log(typeof itm.money)
			// console.log(typeof Number(itm.money))
			if (this.barType == 1) {
				this.rankBoostingEdit(itm);
			} else if (this.barType == 2) {
				this.placementMatchesEdit(itm);
			
			} else if (this.barType == 3) {
				this.unratedGamesPriceEdit(itm);
			}

		},
		feature_onblur(e, index) {
			let name = e + '-' + index;
			let input = document.getElementById(name);
			input.blur();
		},
		acc_onblur(e, index) {
			let name = e + '-' + index;
			let input = document.getElementById(name);
			input.blur();
		},
		feature_inputBlur(item) {
			if (!item.increase) {
				this.getFeatureList();
				return;
			}
			if (item.increase < 0 || item.increase > 100) {
				ElMessage('Please enter the correct percentage');
				this.getFeatureList();
				return;
			}
			this.featureEdit();
		},
		acc_inputBlur(item) {
			console.log(item)
			if (!item.increase) {
				this.getAccList();
				return;
			}
			if (item.increase < 0) {
				ElMessage('Please enter the correct percentage');
				this.getAccList();
				return;
			}
			// if(item.id !== 5){
			// 	if (item.increase < 0 || item.increase > 100) {
			// 		ElMessage('Please enter the correct percentage');
			// 		this.getAccList();
			// 		return;
			// 	}
			// }else{
			// 	if (item.increase < 0) {
			// 		ElMessage('Please enter the correct percentage');
			// 		this.getAccList();
			// 		return;
			// 	}
			// }
			// if (item.increase < 0 || item.increase > 100) {
			// 	ElMessage('Please enter the correct percentage');
			// 	this.getAccList();
			// 	return;
			// }
			this.accEdit();
		},
		feature_switch(item) {
			
		},
		acc_switch(item) {
			this.accEdit();
		},
		description_switch(item){
			
			this.featureEdit();
		},
		openDesciptionPop(item,index){
			this.bg = true;
			this.description_pop = true;
			this.description = item.description;
			this.editIndex = index;
		},
		save_featuresEdit(){
			this.featureList[this.editIndex].description = this.description;
			this.featureEdit();
			this.close();
		}

	},
}
</script>
<style>
.item-arr .el-switch__core {
	border-radius: 5px;
}

.item-arr .el-switch__core .el-switch__action {
	border-radius: 5px;
}
</style>
<style scoped>
.box {
	display: flex;
	width: 100%;
	height: 100%;
	position: relative;
}

.box .icon_del {
	width: 30px;
	height: 30px;
	background-image: url(../../assets/img/calculator/close.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 90%;
	position: absolute;
	right: 0px;
	top: 0px;
}

.box .left {
	width: 20%;
	min-width: 270px;
	height: 100%;
	border-right: 2px solid var(--background-light2);
	padding: 0 20px;
}

.box .left li {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text-color);
	height: 50px;
	margin-bottom: 5px;
}

.box .left li .title {
	border-radius: 200px;
	font-size: 14px;
	text-align: left;
	width: 60%;
	padding: 0 20px;
	display: flex;
	align-items: center;
}

.box .left li .title span {
	white-space: nowrap;
}

.box .left li .title img {
	width: 20px;
	/* height: 20px; */
	margin-right: 10px;
	object-fit: cover;
}

.box .left li.active .title {
	background-color: var(--submit);
	height: 50px;
	line-height: 50px;

}

.box .left li .icon_edit {
	width: 20px;
	height: 20px;
	background-image: url(../../assets/img/table/edit.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	margin: 0 10px;
}

.box .right {
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	padding: 10px 20px;
}

.box .right .tab {
	display: flex;
	width: 120px;
	height: 30px;
	line-height: 30px;
	border-radius: 200px;
	background-color: var(--background-light3);
}

.box .right .tab .item {
	width: 100%;
	color: var(--text-color-light);
	cursor: pointer;
}

.box .right .tab .item.active {
	color: var(--text-color);
	background-color: var(--submit);
	border-radius: 200px;
}

.box .right .frist-box .number {
	display: flex;
	align-items: center;
	margin: 30px 0;
}

.box .right .frist-box .number .num {
	color: var(--text-color);
	background-color: var(--submit);
	border-radius: 50%;
	margin-right: 10px;
	text-align: center;
	line-height: 24px;
	height: 24px;
	width: 24px;

}

.box .right .frist-box .number .txt {
	color: var(--text-color);
	font-size: 16px;
}

.box .right .frist-box .con {
	text-align: left;
}

.box .right .frist-box .con .item-arr {
	display: inline-block;
	margin: 0 10px 10px 10px;
	width: calc(25% - 20px);
	color: var(--text-color);
}

.box .right .frist-box .con .item-arr li {
	white-space: nowrap;
	width: 100%;
	height: 26px;
	line-height: 26px;
	margin: 10px 0;
}

.box .right .frist-box .con .item-arr li span {
	display: inline-block;
	width: 50%;
	text-align: center;
	font-size: 14p;
}

.box .right .frist-box .con .item-arr li input {
	display: inline-block;
	border: 1px solid var(--border);
	border-radius: 5px;
	width: 60px;
	height: 26px;
	line-height: 26px;
	padding: 0 5px;
	color: var(--text-color-light);
}

.box .right .frist-box .con .item-arr li .icon.icon_yes {
	width: 26px;
	height: 26px;
	display: inline-block;
	background-position: center;
	background-size: 75%;
	background-repeat: no-repeat;
	vertical-align: middle;
	margin-left: 5px;
	cursor: pointer;
}

.box .right .frist-box .con .item-arr li .icon_yes {
	background-image: url(../../assets/img/calculator/yes.png);
}

.box .right .frist-box .con .item-arr li .icon_no {
	background-image: url(../../assets/img/calculator/no.png);
}

.box .right .second-box .number {
	display: flex;
	align-items: center;
	margin: 30px 0;
}

.box .right .second-box .number .num {
	color: var(--text-color);
	background-color: var(--submit);
	border-radius: 50%;
	margin-right: 10px;
	text-align: center;
	line-height: 24px;
	height: 24px;
	width: 24px;

}

.box .right .second-box .number .txt {
	color: var(--text-color);
	font-size: 16px;
}

.box .right .second-box .con {
	text-align: left;
}

.box .right .second-box .con .item-arr {
	margin: 0 10px 10px 10px;
	/* width: calc(50% - 20px); */
	color: var(--text-color);
}

.box .right .second-box .con .item-arr li {
	white-space: nowrap;
	width: 100%;
	display: flex;
	align-items: center;
	/* height: 26px;
	line-height: 26px; */
	margin: 5px 0;
	text-align: left;
}

.box .right .second-box .con .item-arr li .name {
	display: inline-block;
	width: 20%;
	/* text-align: ; */
	font-size: 14px;
	overflow: scroll;
	margin-right:5px;
}
::-webkit-scrollbar {
/*隐藏滚轮*/
display: none;
}


.box .right .second-box .con .item-arr li .percent {
	display: inline-block;
	color: var(--text-color-light);
	font-size: 14px;
	margin-right: 10px;
	margin-left: 5px;
}

.box .right .second-box .con .item-arr li input {
	display: inline-block;
	border: 1px solid var(--border);
	border-radius: 5px;
	width: 30px;
	height: 26px;
	line-height: 26px;
	padding: 0 5px;
	color: var(--text-color-light);
}
.box .right .second-box .con .item-arr li .txt{
	width: 50%;
	margin-left: 40px;
}
.box .right .second-box .con .item-arr li .edit-icon {
	width: 20px;
	height: 20px;
	background-image: url(../../assets/img/table/edit.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	margin: 0 10px;
	cursor: pointer;
}

.box .right .second-box .con .add {
	color: var(--submit);
	margin: 10px;
	cursor: pointer;
}

.box .right .third-box .number {
	display: flex;
	align-items: center;
	margin: 30px 0;
}

.box .right .third-box .number .num {
	color: var(--text-color);
	background-color: var(--submit);
	border-radius: 50%;
	margin-right: 10px;
	text-align: center;
	line-height: 24px;
	height: 24px;
	width: 24px;

}

.box .right .third-box .number .txt {
	color: var(--text-color);
	font-size: 16px;
}

.box .right .third-box .con {
	text-align: left;
}

.box .right .third-box .con .item-arr {
	margin: 0 10px 10px 10px;
	/* width: calc(50% - 20px); */
	color: var(--text-color);
}

.box .right .third-box .con .item-arr li {
	white-space: nowrap;
	width:calc(50% - 20px);
	display: inline-block;
	height: 26px;
	line-height: 26px;
	margin: 5px 0;
	text-align: left;
}

.box .right .third-box .con .item-arr li .name {
	display: inline-block;
	width: 40%;
	/* text-align: ; */
	font-size: 14px;
}


.box .right .third-box .con .item-arr li .percent {
	display: inline-block;
	color: var(--text-color-light);
	font-size: 14px;
	margin-right: 10px;
	margin-left: 5px;
}

.box .right .third-box .con .item-arr li input {
	display: inline-block;
	border: 1px solid var(--border);
	border-radius: 5px;
	width: 30px;
	height: 26px;
	line-height: 26px;
	padding: 0 5px;
	color: var(--text-color-light);
}

.box .right .third-box .con .add {
	color: var(--submit);
	margin: 10px;
	cursor: pointer;
}

.pop .tab-box {
	width: 100%;
	border-radius: 20px;
	background-color: var(--background-light);
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-top: 10px;
}

.pop .tab-box .item {
	width: 50%;
	text-align: center;
	line-height: 35px;
	cursor: pointer;
	color: var(--text-color-light);
}

.pop .tab-box .item.active {
	background-color: var(--background-light3);
	border-radius: 20px;
	color: var(--text-color);
}

.pop .inp-box .left {
	padding: 0;
	margin-top: 20px;
}
</style>
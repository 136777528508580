let baseUrl = '';
let wsAddr = '';
let apiAddr='';
let EchowsHost = '';
let Echokey = '';
if (process.env.NODE_ENV === 'development'){
	baseUrl = '/api'
	baseUrl='https://goboost-st.digitalsnail.cn/api/v1/admin/'//st
	// baseUrl='https://goboost.digitalsnail.cn/api/v1/admin/'//dev
	apiAddr = "https://im.digitalsnail.cn/"//st
	wsAddr = "wss://wconnect.digitalsnail.cn/im/"//st
	EchowsHost = "goboost-st.digitalsnail.cn";//st
	Echokey = "987321"
	// baseUrl='https://api.goboost.cc/api/v1/admin/'//prod
    // apiAddr = "https://im.goboost.cc/api"
    // wsAddr = "wss://im.goboost.cc/msg_gateway"
}
if(process.env.NODE_ENV === 'production'){
	// baseUrl='https://goboost.digitalsnail.cn/api/v1/admin/'//dev
	// baseUrl='https://goboost-st.digitalsnail.cn/api/v1/admin/'//st
	// apiAddr = "https://im.digitalsnail.cn/"//st
	// wsAddr = "wss://wconnect.digitalsnail.cn/im/"//st
	// EchowsHost = "goboost-st.digitalsnail.cn";//st
	// Echokey = "987321"
	EchowsHost = "api.goboost.cc";//prod
	Echokey = "123goboost"
	baseUrl='https://api.goboost.cc/api/v1/admin/'//prod
    apiAddr = "https://im.goboost.cc/api"
    wsAddr = "wss://im.goboost.cc/msg_gateway"
	
}
export {
	EchowsHost,
	Echokey,
	baseUrl,
	wsAddr,
    apiAddr
}
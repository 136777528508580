<template>
    <div class="access_box">
        <div class="top">
            <div class="top">
                <ul class='left'>
                    <li :class="tab == 1 ? 'active' : ''" @click="tab = 1">
                        <span>Admin</span>
                    </li>
                    <!-- <div class="line"></div>
                    <li :class="tab == 2 ? 'active' : ''" @click="tab = 2">
                        <span>In Progress</span>
                    </li> -->
                    <div class="line"></div>
                    <li :class="tab == 2 ? 'active' : ''" @click="tab = 2">
                        <span>Booster</span>
                    </li>

                </ul>
            </div>
        </div>
        <div class="con">
            <div class="left">
                <div class="columns">
                    <div class="for"  v-for="(item, index) in list" :key="index" >
                    <div  class="item">
                        <div class="txt" v-html="item.name"></div>
                        <el-switch v-model="item.status" class="ml-2" :active-value="1" :inactive-value="0" @change="switch_status(item)"
                            style=" margin-bottom: -10px;--el-switch-on-color: var(--submit); --el-switch-off-color:var(--background-light2)" />
                    </div>
                    <template v-if="item.children">
                        <div  v-for="(itm, idx) in item.children" :key="idx" class="item">
                        <div style="margin-left: 10px;" :style="{'opacity':item.status==0?0.2:1}" class="txt">{{ itm.name }}</div>
                        <el-switch v-model="itm.status" class="ml-2" :active-value="1" :inactive-value="0" @change="switch_status(itm)" :disabled="item.status==0"
                            style=" margin-bottom: -10px;--el-switch-on-color: var(--submit); --el-switch-off-color:var(--background-light2)" />
                    </div>
                    </template>
                    
                </div>
                </div>
                
               
            </div>
            <div class="right"></div>
        </div>
    </div>
</template>
<script>
import { ElMessage, ElLoading } from 'element-plus'
import Api from '../../utils/api.js';
export default {
    data() {
        return {
            tab: 1,
            list: [],
        }
    },
    mounted() {
        this.getList();
    },
    watch:{
        tab(e){
            this.getList();
        }
    },
    methods: {
        switch_status(e){
            Api.accessSettingEdit({
                id:e.id
            }).then(res => {
                if (res.code == 200) {
                    this.getList();
                } else {
                    ElMessage.error(res.message);
                }
            })
        },
        getList() {
            Api.accessSettingList({
                role: this.tab
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data;
                } else {
                    ElMessage.error(res.message);
                }
            })
        }
    }
}
</script>
<style scoped>
.access_box {
    width: calc(100%);
    height: calc(100%);
    background-color: var(--background-color);
    margin: -20px;
    padding: 20px;
}

.top .left li {
    width: 100px;
    cursor: pointer;
}

.con {
    width: 100%;
    height: calc(100% - 33px);
    margin-top: 20px;
}

.con .left {
    background-color: var(--background-light);
    border-radius: 30px;
    width: calc(100% - 100px);
    /* margin-right: 10px; */
    height: calc(100% - 40px);
    padding: 20px 50px;
    color: var(--text-color);
    overflow-y: scroll;
	
}
.con .left .columns{
    width: 100%;
    text-align: left;
    columns: 2;
	column-gap: 100px;
}
.con .left .for{
    width: calc(50% - 50px);
    /* display: inline-block; */
    width: -webkit-fill-available;
	/* width: calc(50% - 60px); */
	break-inside: avoid;
}

.con .left .item {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--img-border);
    height: 50px;
    width: 100%;
    font-size: 16px;
}</style>
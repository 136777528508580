<template>
    <div class="note_border">
        <div class="note_item" v-for="(item, index) in notificationList" :key="index" @click="goChat(item)">
            <div class="item_img">
                <img :src="item.senderFaceUrl" />
            </div>
            <div class="item_text">
                <p>{{ item.senderNickname }}</p>
                <p v-if="item.contentType == 101">{{ item.textElem.content }}</p>
                <img v-if="item.contentType == 102" :src="item.pictureElem.snapshotPicture.url" />
            </div>
        </div>
        <!-- <div  class="note_item" @click="goChat(item)">
            <div class="item_img">
                <img src="../../assets/img/image 24.png" />
            </div>
            <div class="item_text">
                <p>name</p>
                <p>content</p>
            </div>
        </div> -->
    </div>
</template>

<script>
import Api from "../../utils/api"
import { ElMessage, ElLoading } from 'element-plus'
// import { getSDK } from '../../utils/openIM';
import {
    CbEvents
} from "../../utils/openIM/constant/index.js";
// const OpenIM = getSDK();
import { apiAddr,wsAddr } from '../../utils/config.js'
export default {
    data() {
        return {
            notificationList: [],
        }
    },
    mounted() {
        console.log('notifciation=====')
        // this.$store.dispatch('initOpenIm');
        const nowTime = Math.round(new Date() / 1000);
        const timer = localStorage.getItem('expires_in')
        if (localStorage.getItem('token') && timer > nowTime) {
            setTimeout(() => {
                this.$OpenIM.getLoginStatus().then(({
                    data
                }) => {
                    console.log(data)
                    // if (data !== 3 && data !==2) {
                    //     this.loginIm();
                    // }
                    if (data == 1 || data == -1001) {
                        this.loginIm();
                    }
                    // data: LoginStatus
                }).catch(({
                    errCode,
                    errMsg
                }) => {
                    console.log(errCode, errMsg);
                    if(errCode == undefined){
                       this.loginIm();
                    }
                    // logOut(2);
                    // Call failed
                    //this.loginIm();
                });
            }, 1000);

            let that = this;
            //   OpenIM.on(CbEvents.OnConversationChanged,
            //     ((res) => {
            //         // data 会话信息
            //         console.log("OnConversationChanged===============")
            //         console.log(res.data);
            //         res.data.forEach((item, index) => {
            //             if (item.unreadCount > 1) {
            //                 this.updateIsRead(item.conversationID);
            //             }
            //         })
            //     })
            // );
            this.$OpenIM.on(CbEvents.OnRecvNewMessages, ({
                data
            }) => {
                // data is the new message
                console.log('OnRecvNewMessage note=========')
                console.log(data)
                let obj = {}

                data.forEach((item, index) => {
                    // this.updateIsRead(item.clientMsgID);

                    if (item.recvID == localStorage.getItem('chat_userID') && !item.isRead) {
                        // obj = {
                        //     clientMsgID: item.clientMsgID,
                        //     senderFaceUrl: item.senderFaceUrl,
                        //     senderNickname: item.senderNickname,
                        //     content: item.textElem.content,
                        //     sendID: item.sendID,
                        //     groupID: item.groupID
                        // }
                        that.notificationList.unshift(item)

                        if (that.notificationList.length > 3) {
                            that.notificationList = that.notificationList.slice(0, 3);

                        }
                        if (that.notificationList.length > 0) {
                            setTimeout(() => {
                                that.notificationList.pop()
                            }, 6000)
                        }
                        that.authMe();
                    }

                    console.log('notificationList=====')
                    console.log(that.notificationList)

                })

            });
        }
    },
    methods: {

        updateIsRead(e) {
            let temp = new Date().getTime();
             this.$OpenIM.markConversationMessageAsRead({
                operationID: temp.toString(),
                conversationID: e.toString(),
            }).then((data) => {
                console.log('未读更新成功')
                console.log(data)
            }).catch(({ errCode, errMsg }) => {
                console.log('未读更新失败')
                console.log(errMsg)
            });
        },
        authMe() {
            Api.authMe().then(res => {
                if (res.code == 200) {
                    localStorage.setItem('unread', res.data.unread);
                    this.$emit('getUnread', res.data.unread)
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message)
            })
        },
        loginIm() {
            const config = {
                userID: localStorage.getItem('chat_userID'), // IM user userID
                token: localStorage.getItem('chat_token'),
                platformID: 5, //  1: IOS, 2: Android, 3: Windows, 4: OSX, 5: Web, 6: MiniWeb, 7: Linux, 8: Android Pad, 9: IPad, 10: admin
                apiAddr: apiAddr, // IM api address, generally `http://xxx:10002` or `https://xxx/api
                wsAddr: wsAddr // IM ws address, generally `ws://your-server-ip:10001`(open-im-sdk-wasm) or `ws://your-server-ip:10003`(open-im-sdk)
            }
            this.$OpenIM.login(config).then(res => {
                console.log(res)
                console.log("login suc...");
            }).catch(err => {
                console.log("login failed...");
                console.log(err)
                console.log("login failed...");

            })
        },

        goChat(item) {
            console.log(item);
            this.$emit('getUnread', res.data.unread)
            this.$router.push({
                path: '/chat'
            })
        }
    }
}
</script>

<style scoped>
.note_border {
    position: absolute;
    right: 20px;
    z-index: 9999;
    position: fixed;
    top: 100px;
    width: 20%;
    min-width: 260px;
}

.note_border .note_item {
    cursor: pointer;
    display: flex;
    box-shadow: 1px 1px 9px;
    margin-bottom: 10px;
    background: var(--background-light3);
    color: #fff;
    padding: 10px 20px;

    box-shadow: 0px 0px 23px 0px #E81F760A inset;
    clip-path: polygon(10px 0px, calc(100% - 10px) 0, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100% - 10px), 0 10px);
}

.note_border .note_item img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
}

.note_border .note_item .item_text {
    text-align: left;
    padding-left: 10px;
}

.note_border .note_item .item_text p {
    margin: 0;
}

.note_border .note_item .item_text img {
    border-radius: 0;
    object-fit: contain;
}

.note_border .note_item .item_text p:nth-child(1) {
    font-size: 16px;
    font-family: "MazzardM-Bold";
}

.note_border .note_item .item_text p:nth-child(2) {
    font-size: 16px;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    word-break: break-all;
}
</style>
import {
	createApp,
	reactive
} from 'vue'
import App from './App.vue'
import router from './router'
import './router/guard.js'
import store from './store'

import i18n from "./lang/index.js"

import ElementUI from 'element-plus'
import 'element-plus/dist/index.css';

import './assets/css/global.css'
import './assets/css/root.css'
import './assets/css/base.less'
import "./assets/fonts/font.css"

import VueCropper from 'vue-cropper'; 
import 'vue-cropper/dist/index.css'
const app = createApp(App);
/** 定义一个函数，用于对象链式取值 */
import { getSDK } from './utils/openIM';
app.config.globalProperties.$OpenIM = getSDK();
app.use(i18n).use(store).use(router).use(VueCropper).use(ElementUI).mount('#app')
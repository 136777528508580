<template>
	<div class="login">
		<el-container>
			<el-header>
				<div class="icon icon-headerLogo"></div>
			</el-header>
			<el-main>
				<div class="pop">
					<div class="title">Log in</div>
					<div class="inp-box">
						<input type="text" placeholder="Log In" v-model="email" />
					</div>
					<div class="inp-box eye">
						<input :type="password_see ? 'text' : 'password'" placeholder="Password" v-model="password" />
						<img class="close" v-if="password_see" src="../../../assets/img/close-eye.png"
							@click="password_see = false" alt="" />
						<img class="open" v-else src="../../../assets/img/eye.png" @click="password_see = true" alt="" />

					</div>
					<div class="text" @click="goReset">Forgot Password?</div>
					<div class="btn-box">
						<button class="submit" @click="login">LOG IN</button>
					</div>
					<!-- <div class="text-gray">
						Don’t have an account?
						<span class="pink" @click="signUp">
							Sign up for free
						</span>
					</div> -->
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import {
	ElMessage
} from 'element-plus'

import Api from '../../../utils/api.js'
export default {
	name: 'login',
	data() {
		return {
			password_see: false,
			email: '',
			password: ''
		}
	},
	created() {

	},
	methods: {
		login_chat() {
			Api.chatToken().then(res => {
				if (res.code == 200) {
					localStorage.setItem('chat_token', res.data.token);
					localStorage.setItem('chat_expireTimeSeconds', res.data.expireTimeSeconds);
					localStorage.setItem('chat_userID', res.data.userID);

					this.$router.push({
						name: 'dashboard/user',
					})
				} else {
					ElMessage.error(res.message);
				}
			})
		},
		login() {
			if (this.email == '' || this.password == '') {
				ElMessage.warning('Please complete your login information');
				return;
			} else {
				var reg = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,}$/;
				// if (!reg.test(this.password)) {
				// 	ElMessage('Wrong password');
				// 	return;
				// }
				Api.login({
					email: this.email,
					password: this.password
				}).then(res => {
					if (res.code == 200) {
						localStorage.setItem('token', res.data.access_token);
						localStorage.setItem('token_type', res.data.token_type);
						localStorage.setItem('expires_in', res.data.expires_in);
						localStorage.setItem('user_id', res.data.user_id);
						this.login_chat();
					} else {
						ElMessage.error(res.message);
					}
				})
			}
		},
		goReset() {
			this.$router.push({
				name: 'reset_email',
			})
		},
		signUp() {

		}
	}
}
</script>

<style scoped>
.el-header {
	height: 100px;
	width: 100%;
	background-color: var(--background-color);

}

.el-header .icon {
	height: 74px;
	width: 210px;
	margin: 13px auto;
}

.el-main {
	background-image: url(../../../assets/img/bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: calc(100vh - 100px);
	display: flex;
	justify-content: center;
	align-items: center;

}

.pop .btn-box .submit {
	width: 100%;
	margin-bottom: 10px;
}
</style>
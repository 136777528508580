import { createStore } from 'vuex'
// import { getSDK } from '../utils/openIM';
export default createStore({
  state: {
	  phone:'',
	  password:'',
	  code:'',
	  countryName:'',
    // OpenIM:null
  },
  // state() {
  //   return {
  //     OpenIM:null
  //   };
  // },
  getters: {
  },
  mutations: {
    // initOpenIm(state){
		// 	state.OpenIM = getSDK();
		// }
  },
  actions: {
    // initOpenIm({ commit }) {
    //   commit('initOpenIm');
    // },
  },
  modules: {
  }
})

export default {
 adminPanel:'Admin Paneli',
 	connect:'connect',
	logOut:'Çıkış Yap',
 	login:'Giriş yapmak',
 	password:'Şifre',
 	signIn:'Kayıt olmak',
 	thisFieldIsRequired:'Bu alan gereklidir',
	loginSMS1:'Numaranıza kod içeren bir SMS gönderildi',
	loginSMS2:'lütfen girin.',
	enter:'Girmek',
	code:'kod',
	userOnline:'Kullanıcı çevrimiçi',
	adminChat:'Yönetici sohbeti',
	menu: [{
			name: 'Kullanıcı hesapları',
			id: 0,
			message: 0,
			path: '/index',
	
			children: [{
				name: 'Silinen hesaplar',
				id: 0,
				message: 0,
				path: '/deleted-account',
	
			}, {
				name: 'Doğrulama belgeleri',
				id: 1,
				message: 0,
				path: '/verification-documents',
	
			}, ]
		},
		// {
		//   name: 'Subscription',
		//   id: 1,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'List of administrators',
		//   id: 2,
		//   message: 0,
		//   path:'',
	
		// },
		{
			name: 'Emirler',
			id: 3,
			message: 0,
			path: '/orders',
	
			children: [{
				name: 'Yardım talebi',
				id: 0,
				message: 0,
				path: '/request-for-help',
	
			}]
		},
		{
			name: 'Hizmetler',
			id: 4,
			message: 0,
			path: '/services',
	
			children: [{
				name: 'Moderasyondaki hizmetler',
				id: 0,
				message: 0,
				path: '/service-on-moderation',
	
			}]
		},
		// {
		//   name: 'Content page',
		//   id: 5,
		//   message: 0,
		//   path:'',
	
		// },
		// {
		//   name: 'Promotion',
		//   id: 6,
		//   message: 0,
		//   path:'',
	
		// },
		// {
		//   name: 'Purchased promotion',
		//   id: 7,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'Refund',
		//   id: 8,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'blog',
		//   id: 9,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'support',
		//   id: 10,
		//   message: 0,
		//   path:'',
	
		//   children: [{
		//     name: 'Feedback for support',
		//     id: 0,
		//     message: 0,
		//     path:'',
	
		//   }]
		// }, {
		//   name: 'Data management',
		//   id: 11,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'FAQ',
		//   id: 12,
		//   message: 0,
		//   path:'',
	
		// },
		{
			name: 'İlçeler',
			id: 13,
			message: 0,
			path: '/districts',
	
		},
		// {
		//         name: 'Complaints',
		//         id: 14,
		//         message: 0,
		//         path:'',
	
		//         children: [{
		//           name: "Types of complaints",
		//           id: 0,
		//           message: 0,
		//           path:'',
	
		//         }, {
		//           name: "All complaints from users",
		//           id: 1,
		//           message: 0,
		//           path:'',
	
		//         }]
		//       },
		//       {
		//         name: "Organization structure",
		//         id: 15,
		//         message: 0,
		//         path:'',
	
		//       }
	
	
	
	]
	
}
<template>
    <div class="box">
        <div class="top">
            <ul class='left'>
                <li :class="tab == 1 ? 'active' : ''" @click="tab = 1">
                    <span>Contact</span>
                </li>
                <!-- <div class="line"></div>
                    <li :class="tab == 2 ? 'active' : ''" @click="tab = 2">
                        <span>In Progress</span>
                    </li> -->
                <div class="line"></div>
                <li :class="tab == 2 ? 'active' : ''" @click="tab = 2">
                    <span>Official</span>
                </li>

            </ul>
        </div>
        <div class="con-table">
            <table v-if="tab == 1">
                <thead>
                    <tr>
                        <th style="width: 20%;">Id</th>
                        <th style="width: 40%;">About</th>
                        <th style="width: 20%;">Name</th>
                        <th style="width: 20%;">Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td>{{ item.id }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>

                    </tr>
                </tbody>
            </table>
            <table v-if="tab == 2">
                <thead>
                    <tr>
                        <th style="width: 35%;">Email</th>
                        <th style="width: 35%;">Discord</th>
                        <th style="width: 30%;">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in officialList" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td>{{ item.email }}</td>
                        <td>{{ item.discord }}</td>
                        <td class="icon_box">
                            <div class="icon icon_edit" @click="openPop(item)"></div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
        <pagination v-if="tab==1" :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
            @backPage='backPage'></pagination>


        <div class="pop-bg" v-if="bg" @click="close()">

            <div class="pop_box" v-if="pop" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Official information
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="email">E-mail</div>
                        <input v-model="email" placeholder="E-mail" :style="{ 'margin-top': email ? '5px' : '20px' }" />
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="discord">Discord</div>
                        <input v-model="discord" placeholder="Discord"/>
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">Cancel</button>
                        <button class="submit" @click="save">Save</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import {
    ElMessage
} from 'element-plus'
import Api from '../../utils/api.js';
import pagination from '../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            tab: 1,
            pageSize: 8,
            nowPage: 1,
            total: 0,
            list: [],
            officialList:[],
            bg: false,
            pop: false,
            id: '',
            email:'',
            discord:''
        }
    },
    watch: {
        tab(e) {
            this.nowPage = 1;
            this.total = 0;
            if (e == 1) {
                this.getList();
            } else {
                this.getOfficialList();
            }
        }
    },
    mounted() {
        let table = document.getElementsByClassName('con-table')[0];
        this.pageSize = parseInt((table.clientHeight - 50) / 50);
        this.getList();
    },
    methods: {
        save() {
            if (this.email == '' || this.discord == '' ) {
                ElMessage('Please complete the information.');
                return;
            }
            Api.contactOfficialEdit({
                id:this.id,
                email:this.email,
                discord:this.discord,
            }).then(res => {
                if (res.code == 200) {
                    this.getOfficialList();
                    this.close();
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })

        },
        getList() {
            Api.contactList({
                limit: this.pageSize,
                page: this.nowPage
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data.data;
                    this.total = res.data.meta.pagination.total;
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        getOfficialList() {
            this.officialList = [];
            Api.contactOfficial().then(res => {
                if (res.code == 200) {
                    let obj = res.data;
                    this.officialList.push(obj)
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        openPop(item) {
            this.bg = true;
            this.pop = true;
            this.id = item.id;
            this.email = item.email;
            this.discord = item.discord;

        },
        close() {
            this.bg = false;
            this.pop = false;
            this.email = '';
            this.discord = '';
        },
    }
}
</script>
<style scoped>
.box {
    width: 100%;
    height: 100%;
    position: relative;
}

.top .left {
    width: 200px;
    margin-bottom: 10px;
}

.top .left li {
    width: 50%;
    cursor: pointer;
}

.con-table {
    height: calc(100% - 106px);
}

.pop .img-box img,
.photo-box {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    margin: auto;
}

.pop .img-box .photo-box {
    border: 1px solid var(--img-border)
}

.pop .img-box .img-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

}

.pop .img-box .img-btn .left-btn,
.right-btn {
    border: 1px solid var(--submit);
    border-radius: 20px;
    color: var(--text-color);
    padding: 10px 0;
    width: 100px;
    cursor: pointer;
}

.pop .img-box .img-btn .left-btn {
    position: relative;
    margin-right: 10px;
}

.pop .img-box .img-btn .left-btn input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    cursor: pointer;
}

.pop .img-box .img-btn .right-btn {
    background-color: var(--submit);
}
.con-table table tr th,td{
    text-align: center;
}
</style>
<template>
    <div class="boosterInfo">
        <div class="top">
            <div class="flex">
                <img @click="back" src="../../../assets/img/back_submit.png">
                <ul class='left'>
                    <li :class="tab == 1 ? 'active' : ''" @click="tab = 1">
                        <span>User info</span>
                    </li>
                    <!-- <div class="line"></div>
                    <li :class="tab == 2 ? 'active' : ''" @click="tab = 2">
                        <span>Portfolio</span>
                    </li> -->
                    <div class="line"></div>
                    <li :class="tab == 3 ? 'active' : ''" @click="tab = 3">
                        <span>Reviews</span>
                    </li>
                    <div class="line"></div>
                    <li :class="tab == 4 ? 'active' : ''" @click="tab = 4">
                        <span>Orders</span>
                    </li>
                    <div class="line"></div>
                    <li :class="tab == 5 ? 'active' : ''" @click="tab = 5">
                        <span>Settings</span>
                    </li>

                </ul>
            </div>
            <div class="right" v-if="tab != 1 && tab != 5">
                <div class="date">
                    <el-date-picker v-model="date" type="date" placeholder="" :disabled-date="disabledDate" size="large"
                        format="DD.MM.YYYY" />
                </div>
            </div>
        </div>
        <div class="con" v-if="tab == 1">
            <div class="frist-item" v-if="boosterInfo">
                <div class="info">
                    <div class="info-left">
                        <img class="avatar" :src="boosterInfo.avatar">
                        <div class="message">
                            <div class="flex-between">
                                <div class="name">
                                    {{ boosterInfo.username }}
                                    <span class="online" v-if="boosterInfo.status == 1"></span>
                                </div>
                                <div class="country">
                                    <img :src="boosterInfo.country">
                                </div>
                            </div>
                            <div class="flex">
                                <div class="item">
                                    <img v-for="item in Math.ceil(boosterInfo.rate)" :key="item"
                                        src="../../../assets/img/star.png">
                                    <span>{{ boosterInfo.rate }}</span>
                                </div>
                                <div class="item">
                                    <img :src="boosterInfo.current_rank_image">
                                    <span>{{ boosterInfo.current_rank }}</span>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="item gray">
                                    <div class="num">{{ boosterInfo.reviews }}</div>
                                    <span>Reviews</span>
                                </div>
                                <!-- <div class="item gray">
                                    <div class="num">{{ boosterInfo. }}</div>
                                    <span>Games Won</span>
                                </div> -->
                                <div class="item gray">
                                    <div class="num">{{ boosterInfo.orders_completed }}</div>
                                    <span>Orders Completed</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-right">
                        <div class="item">
                            <span>Boosters income</span>
                            <div>{{ boosterInfo.booster_income }}$</div>
                        </div>
                        <div class="item">
                            <span>Our income</span>
                            <div>{{ boosterInfo.our_income }}$</div>
                        </div>
                        <button @click="goNext">Current balance</button>
                    </div>
                </div>
                <div class="detail">
                    <div>Detail</div>
                    <p>{{ boosterInfo.details }}</p>
                </div>
            </div>
        </div>
        <div class="con-table" v-if="tab == 3">
            <table>
                <thead>
                    <tr>
                        <th style="width: 10%;">Client</th>
                        <th style="width: 20%;">Ratings</th>
                        <th style="width: 28%;">Text</th>
                        <th style="width: 28%;">Photo</th>
                        <th style="width: 14%;">Date/Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in reviewList" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td style="text-align: center;">{{ item.client }}</td>
                        <td>
                            <img style="width: 20px;margin-right:5px;" v-for="item in Math.ceil(item.rate)" :key="item"
                                src="../../../assets/img/table/star.png">
                            <span style="color:#fff">{{ item.rate }}</span>
                        </td>
                        <td>{{ item.comment }}</td>
                        <td>
                            <!-- <img v-for="(itm,indx) in item.photo" :key="indx" :src="itm"> -->
                            <!-- <img src="../../../assets/img/table/star.png"> -->
                            <el-image v-for="(itm, indx) in item.photo" :key="indx" class="img" :src="itm" :zoom-rate="1.2"
                                :max-scale="7" :min-scale="0.2" :preview-src-list="item.photo" :initial-index="indx"
                                fit="cover" />
                        </td>
                        <td style="text-align: center;">{{ getDate(item.created_at) }}</td>

                    </tr>
                </tbody>
            </table>
            <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
                @backPage='backPage'></pagination>
        </div>
        <div class="con-table" v-if="tab == 4">
            <table>
                <thead>
                    <tr>
                        <th style="width: 10%;" class="icon_box">
                            ID
                            <div class="icon icon_down" :style="{ 'transform': sort_data == 2 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 1 ? 2 : 1"></div>
                        </th>
                        <th style="width: 14%;" class="icon_box">Client
                            <div class="icon icon_down" :style="{ 'transform': sort_data == 4 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 3 ? 4 : 3"></div>
                        </th>
                        <th style="width: 14%;" class="icon_box">Orders type
                            <div class="icon icon_down" :style="{ 'transform': sort_data == 6 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 5 ? 6 : 5"></div>
                        </th>
                        <th style="width: 30%;">Results
                            <!-- <div class="icon icon_down" :style="{ 'transform': sort_data == 6 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 5 ? 6 :5"></div> -->
                        </th>
                        <th style="width: 13%;" class="icon_box">Price
                            <div class="icon icon_down" :style="{ 'transform': sort_data == 8 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 7 ? 8 : 7"></div>
                        </th>
                        <th style="width: 18%;" class="icon_box">Date/Time
                            <div class="icon icon_down" :style="{ 'transform': sort_data == 10 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 9 ? 10 : 9"></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in orderList" :key="index" :class="index % 2 === 1 ? 'odd' : 'even'">
                        <td>
                            <div class="line">
                                {{ item.id }}
                            </div>
                        </td>
                        <td>
                            <div class="line"> {{ item.client }}</div>
                        </td>
                        <td>
                            <div class="line" v-if="item.type == 1">Rank Boosting</div>
                            <div class="line" v-if="item.type == 2">Placement Matches</div>
                            <div class="line" v-if="item.type == 3">Unrated Games</div>
                            <div class="line" v-if="item.type == 4">Ranked Wins</div>
                            <div class="line" v-if="item.type == 5">Account Leveling</div>
                        </td>
                        <td>
                            <div class="icon_box line" v-if="item.type == 1">
                                <img :src="item.result.current_rank_image" />
                                <span>{{ item.result.current_rank_name }}</span>
                                <img style="width:10px" src="../../../assets/img/table/right.png">
                                <img :src="item.result.desired_rank_image" />
                                <span>{{ item.result.desired_rank_name }}</span>
                            </div>
                            <div class="icon_box line" v-if="item.type == 2">
                                <img :src="item.result.current_tier_image" />
                                <span>{{ item.result.current_tier_name }}</span>
                                <span>, Game Number {{ item.result.game_number }}</span>
                            </div>
                            <div class="icon_box line" v-if="item.type == 3">
                                <span>Game Number {{ item.result.game_number }}</span>
                            </div>
                            <div class="icon_box line" v-if="item.type == 4">
                                <img :src="item.result.current_rank_image" />
                                <span>{{ item.result.current_rank_name }}</span>
                                <span>, Game Number {{ item.result.game_number }}</span>
                            </div>
                            <div class="icon_box line" v-if="item.type == 5">
                                <span>Level {{ item.result.current_level }}</span>
                                <img style="width:10px" src="../../../assets/img/table/right.png">
                                <span>Level {{ item.result.desired_level }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="line"> {{ item.money }}$</div>
                        </td>
                        <td>
                            <div class="line" style="line-height: normal;">
                                {{ getDate(item.created_at) }}
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
            <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
                @backPage='backPage'></pagination>
        </div>
        <div class="con-setting" v-if="tab == 5">
            <div class="con-left">
                <div class="user">
                    <img :src="setting.avatar">
                    <div class="name">
                        {{ setting.username }}
                        <span class="online" v-if="setting.status == 1"></span>
                    </div>
                </div>
                <div class="price">
                    <span>Available balance</span>
                    <div>{{ setting.available_balance }} $</div>
                </div>
            </div>
            <div class="con-right">
                <ul>
                    <li>
                        <div class="txt">Minimum withdraw amount</div>
                        <div class="r">
                            <span>{{ setting.minimum_withdraw_amount }} $</span>
                            <img src="../../../assets/img/edit.png" @click="openPop(1)">
                        </div>
                    </li>
                    <li>
                        <div class="txt">Percentage of order</div>
                        <div class="r">
                            <span>{{ setting.percentage_of_order }} %</span>
                            <img src="../../../assets/img/edit.png" @click="openPop(2)">
                        </div>
                    </li>
                    <li>
                        <div class="txt">Maximum number of withdrawals</div>
                        <div class="r">
                            <span>{{ setting.maximum_withdrawals }} </span>
                            <img src="../../../assets/img/edit.png" @click="openPop(3)">
                        </div>
                    </li>
                    <li>
                        <div class="txt">Automatic ban</div>
                        <div class="r">
                            <span>{{ setting.automatic_ban }} h.</span>
                            <img src="../../../assets/img/edit.png" @click="openPop(4)">
                        </div>
                    </li>
                </ul>
                <div class="ban">
                    <div class="t">Ban</div>
                    <div class="flex">
                        <div class="text">You can withdraw at least $1000</div>
                        <div class="inp" v-if="!setting.ban">
                            <div class="inp-txt">Hours</div>
                            <input v-model="hour">
                        </div>
                        <button v-if="!setting.ban" @click="ban(1)">BAN</button>
                        <button v-if="setting.ban" @click="ban(2)">Relieve BAN</button>
                    </div>
                </div>
            </div>

        </div>
        <div class="pop-bg" v-if="bg" @click="close()">

            <div class="pop_box" v-if="pop" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Edit
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="pop_type == 1">Minimum withdraw amount</div>
                        <div class="text-gray left" v-if="pop_type == 2">Percentage of order</div>
                        <div class="text-gray left" v-if="pop_type == 3">Maximum number of withdrawals</div>
                        <div class="text-gray left" v-if="pop_type == 4">Automatic ban</div>
                        <input v-model="pop_con" placeholder="" />
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">Cancel</button>
                        <button class="submit" @click="save">Save</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import {
    ElMessage, ElLoading
} from 'element-plus'
import Api from '../../../utils/api.js';
import pagination from '../../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            nowPage: 1,
            total: 0,
            pageSize: 0,
            booster_id: '',
            boosterInfo: null,
            tab: 1,
            date: '',
            reviewList: [],
            orderList: [],
            sort_data: '',
            hour: '',
            setting: '',
            bg: false,
            pop: false,
            pop_type: 1,
            pop_con: ''


        }
    },
    watch: {
        tab(e) {
            this.date = '';
            this.nowPage = 1;
            if (e == 1) {
                this.getInfo();
            } else if (e == 3) {
                this.getReviewList();
            } else if (e == 4) {
                this.getOrderList();
            } else if (e == 5) {
                this.getSetting();
            }
        },
        date(e) {
            if (this.tab == 3) {
                this.getReviewList();
            } else {
                this.getOrderList();
            }

        },
        sort_data(e) {
            this.getOrderList();
        }
    },
    mounted() {
        let table = document.getElementsByClassName('boosterInfo')[0];
        this.pageSize = parseInt((table.clientHeight - 110) / 50);
        this.booster_id = this.$route.query.booster_id;
        this.getInfo();
    },
    created() {

    },
    methods: {
        goNext() {
            this.$router.push({
                path: '/statistic/boosters/current_balance',
                query: {
                    booster_id: this.booster_id

                }
            })
        },
        ban(e) {
            if (e == 1) {
                if (this.hour == '') {
                    ElMessage('Please complete the information.');
                    return
                }
            }

            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.boosterBan({
                booster_id: this.booster_id,
                status: e,
                hour: this.hour
            }).then(res => {
                if (res.code == 200) {
                    this.hour = '';
                    this.getSetting();
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message)
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })

        },
        close() {
            this.pop = false;
            this.bg = false;
        },
        save() {
            if (this.pop_con == '') {
                ElMessage('Please complete the information.');
                return
            }
            let data = {
                booster_id: this.booster_id
            }
            if (this.pop_type == 1) {
                data.minimum_withdraw_amount = this.pop_con
            } else if (this.pop_type == 2) {
                data.percentage_of_order = this.pop_con
            } else if (this.pop_type == 3) {
                data.maximum_withdrawals = this.pop_con
            } else if (this.pop_type == 4) {
                data.automatic_ban = this.pop_con
            }
            Api.settingEdit(data).then(res => {
                if (res.code == 200) {
                    this.getSetting();
                    this.close()
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        openPop(e) {
            this.pop_type = e;
            this.bg = true;
            this.pop = true;
            if (e == 1) {
                this.pop_con = this.setting.minimum_withdraw_amount;
            } else if (e == 2) {
                this.pop_con = this.setting.percentage_of_order;
            } else if (e == 3) {
                this.pop_con = this.setting.maximum_withdrawals;
            } else if (e == 4) {
                this.pop_con = this.setting.automatic_ban;
            }
        },
        getSetting() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.boosterSetting({
                booster_id: this.booster_id,
            }).then(res => {
                if (res.code == 200) {
                    this.setting = res.data;
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message)
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        getOrderList() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.orderAll({
                sort: this.sort_data,
                booster_id: this.booster_id,
                limit: this.pageSize,
                page: this.nowPage,
                timestamp: new Date(this.date).getTime() / 1000,
            }).then(res => {
                if (res.code == 200) {
                    this.orderList = res.data.data;
                    this.total = res.data.meta.pagination.total;
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message)
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        getDate(e) {
            const date = new Date(e ? e * 1000 : '');
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${day}.${month}.${year}/${hour}:${minute}`
        },

        getReviewList() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.boosterReviews({
                booster_id: this.booster_id,
                limit: this.pageSize,
                page: this.nowPage,
                timestamp: new Date(this.date).getTime() / 1000,
            }).then(res => {
                if (res.code == 200) {
                    this.reviewList = res.data.data;
                    this.total = res.data.meta.pagination.total;
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message)
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        disabledDate(time) {
            return time.getTime() > Date.now();
        },
        back() {
            this.$router.go(-1);
        },
        goPage(e) {
            this.nowPage = e
            if (this.tab == 3) {
                this.getReviewList();
            } else if (this.tab == 4) {
                this.getOrderList();
            }
            console.log('nowPage:', this.nowPage)
        },
        nextPage(e) {
            this.nowPage = e
            if (this.tab == 3) {
                this.getReviewList();
            } else if (this.tab == 4) {
                this.getOrderList();
            }
            console.log('nowPage:', this.nowPage)
        },
        backPage(e) {
            this.nowPage = e
            if (this.tab == 3) {
                this.getReviewList();
            } else if (this.tab == 4) {
                this.getOrderList();
            }
            console.log('nowPage:', this.nowPage)
        },
        getInfo() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.boosterInfo({
                booster_id: this.booster_id
            }).then(res => {
                if (res.code == 200) {
                    this.boosterInfo = res.data;
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message);
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        }

    },

}
</script>
<style>
.el-image-viewer__close,
.el-image-viewer__next,
.el-image-viewer__prev {
    background-color: var(--background-light3) !important;
}

.el-image-viewer__actions {
    background-color: var(--background-light3) !important;
}

.el-image img {
    border-radius: 0px;
    border: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    max-width: 200px !important;
    max-height: 200px !important;
    object-fit: contain;
}
</style>
<style scoped>
.boosterInfo {
    width: 100%;
    height: calc(100% - 50px);
}

.boosterInfo .top .flex {
    display: flex;
}

.boosterInfo .top .flex img {
    width: 30px;
    height: 30px;
    margin-right: 30px;
    cursor: pointer;
}

.boosterInfo .top .left li {
    padding: 10px 20px;
}

.boosterInfo .con .frist-item {
    width: calc(100% - 40px);
    padding: 20px;
    background-color: var(--background-light2);
    border-radius: 20px;
    margin-top: 20px;
}

.boosterInfo .con .frist-item .info {
    display: flex;
}

.boosterInfo .con .frist-item .info .info-left {
    width: calc(65% - 20px);
    display: flex;
    border-right: 1px solid var(--img-border);
    padding-right: 20px;
}

.boosterInfo .con .frist-item .info .info-left .avatar {
    width: 110px;
    height: 110px;
    border-radius: 20px;
    border: 1px solid var(--img-border);
    margin-right: 10px;
}

.boosterInfo .con .frist-item .info .message {
    width: 100%
}

.boosterInfo .con .frist-item .info .message .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.boosterInfo .con .frist-item .info .flex-between .name {
    color: var(--text-color);
    font-size: 24px;
    position: relative;
}

.boosterInfo .con .frist-item .info .flex-between .name .online {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--text-color-green);
    position: absolute;
    right: -8px;
    top: 0;
}

.boosterInfo .con .frist-item .info .flex-between .country {
    border: 1px solid var(--input-border);
    border-radius: 50%;
    padding: 5px 7px;
}

.boosterInfo .con .frist-item .info .flex-between .country img {
    object-fit: contain;
    width: 20px;
    height: 20px;
}

.boosterInfo .con .frist-item .info .flex {
    display: flex;
    margin-top: 10px;
}

.boosterInfo .con .frist-item .info .flex .item {
    border: 1px solid var(--text-color);
    border-radius: 20px;
    height: 30px;
    padding: 0 10px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.boosterInfo .con .frist-item .info .flex .item.gray {
    border: 1px solid var(--input-border);
}

.boosterInfo .con .frist-item .info .flex .item img {
    height: 15px;
    object-fit: cover;
    margin-right: 5px;
}

.boosterInfo .con .frist-item .info .flex .item .text {
    font-size: 14px;
}

.boosterInfo .con .frist-item .info .flex .item .num {
    padding: 3px 5px;
    border-radius: 20px;
    background-color: var(--submit);
    margin-right: 5px;
}

.boosterInfo .con .frist-item .info-right {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.boosterInfo .con .frist-item .info-right .item {
    border-radius: 20px;
    padding: 8px 10px;
    background-color: var(--background-light3);
    display: flex;
    width: 200px;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color);
    margin-bottom: 10px;

}

.boosterInfo .con .frist-item .info-right .item span {
    margin-left: 5px;
}

.boosterInfo .con .frist-item .info-right .item div {
    background-color: var(--text-color-purple);
    border-radius: 20px;
    padding: 5px 10px;
}

.boosterInfo .con .frist-item .info-right button {
    background-color: var(--submit);
    border-radius: 20px;
    color: var(--text-color);
    padding: 8px 20px;
}

.boosterInfo .con .frist-item .detail {
    /* margin-top: 10px; */
    text-align: left;
}

.boosterInfo .con .frist-item .detail div {
    color: var(--text-color);
    font-size: 16px;
}

.boosterInfo .con .frist-item .detail p {
    line-height: 20px;
    color: var(--text-color-light);
}

.boosterInfo .con-table {
    position: relative;
    width: 100%;
    height: 100%;
}

.boosterInfo .con-table table tr td {
    white-space: normal;
    color: #fff;
    border-right: 1px solid var(--img-border);
    text-align: center;
    padding: 0 5px;
}
.boosterInfo .con-table table tr td .img{
    height: 40px;
    width: 40px;
    margin-right: 5px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.boosterInfo .con-table table tr th {
    color: var(--text-color-light);
    text-align: center;
}

.boosterInfo .con-table table thead tr {
    border-bottom: 1px solid var(--img-border);
}

.boosterInfo .con-setting {
    display: flex;
    margin-top: 20px;

}

.boosterInfo .con-setting .con-left {
    width: calc(30% - 50px);
    border-right: 1px solid var(--img-border);
    padding-right: 50px;

}

.boosterInfo .con-setting .con-left .user {
    display: flex;
    align-items: flex-end;

    margin-bottom: 20px;
}

.boosterInfo .con-setting .con-left .user img {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    margin-right: 10px;
}

.boosterInfo .con-setting .con-left .user .name {
    font-size: 24px;
    font-weight: bolder;
    position: relative;
    color: #fff;
}

.boosterInfo .con-setting .con-left .user .name .online {
    width: 6px;
    height: 6px;
    position: absolute;
    right: -6px;
    height: -6px;
    border-radius: 50%;
    background-color: var(--text-color-green);
}

.boosterInfo .con-setting .con-left .price {
    border-radius: 48px;
    padding: 15px 20px;
    background-color: var(--background-light3);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.boosterInfo .con-setting .con-left .price span {
    color: var(--text-color)
}

.boosterInfo .con-setting .con-left .price div {
    color: var(--text-color-purple);
    font-size: 16px;
    font-weight: bolder;
}

.boosterInfo .con-setting .con-right {
    padding-left: 20px;
    width: calc(70% - 40px);
}

.boosterInfo .con-setting .con-right ul li {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: calc(50% - 80px);
    margin: 0px 20px;
    padding: 15px 20px;
    color: var(--text-color);
}

.boosterInfo .con-setting .con-right ul li .r {
    padding: 10px 20px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    background-color: var(--background-light3);
}

.boosterInfo .con-setting .con-right ul li .r img {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.boosterInfo .con-setting .con-right .ban {
    border-radius: 20px;
    background-color: var(--background-light3);
    color: var(--text-color);
    padding: 20px;
    margin-top: 20px;
}

.boosterInfo .con-setting .con-right .ban .t {
    font-size: 20px;
    text-align: left;
    margin-bottom: 20px;
}

.boosterInfo .con-setting .con-right .ban .flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.boosterInfo .con-setting .con-right .ban .flex .text {
    line-height: 40px;

}

.boosterInfo .con-setting .con-right .ban .flex .inp {
    margin-left: 20px;
    text-align: left;
    width: 33.3%;
}

.boosterInfo .con-setting .con-right .ban .flex .inp input {
    border: 1px solid var(--input-border);
    border-radius: 5px;
    padding: 10px;
    color: var(--text-color-light);
    margin-top: 5px;
    width: calc(100% - 20px);
}

.boosterInfo .con-setting .con-right .ban .flex button {
    border-radius: 20px;
    background-color: var(--submit);
    color: var(--text-color);
    width: 30%;
    padding: 10px 0;
    font-size: 16px;
    margin-left: 20px
}
</style>
<template>
    <div class="box">
        <div class="con">
            <ul class="top">
                <li>
                    <div>
                        <span>Number of orders</span>
                    </div>
                    <div class="right">{{ count.number_of_orders }}</div>
                </li>
                <li>
                    <div>
                        <span>Booster income</span>
                    </div>
                    <div class="right">{{ count.booster_income }}</div>
                </li>
                <li>
                    <div>
                        <span>Our income</span>
                    </div>
                    <div class="right">{{ count.our_income }}</div>
                </li>
            </ul>
            <ul class="type-box">
                <li>
                    <div class="li-top">
                        <div class="">
                            <img src="../../../assets/img/orderType/type_1.png">
                            <span>Rank Boosting</span>
                        </div>
                        <div class="right">{{rank_boosting.sum}}$</div>
                    </div>
                    <div class="li-con">
                        <div class="left">
                            <div class="num red">{{rank_boosting.month}}</div>
                            <div class="txt">month</div>
                        </div>
                        <div class="right">
                            <div class="itm">
                                <div>Boosters income</div>
                                <span>{{rank_boosting.booster_income}}$</span>
                            </div>
                            <div class="itm">
                                <div>Our income</div>
                                <span>{{rank_boosting.our_income}}$</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="li-top">
                        <div class="">
                            <img src="../../../assets/img/orderType/type_2.png">
                            <span>Placement Matches</span>
                        </div>
                        <div class="right">{{placement_matches.sum}}$</div>
                    </div>
                    <div class="li-con">
                        <div class="left">
                            <div class="num red">{{placement_matches.month}}</div>
                            <div class="txt">month</div>
                        </div>
                        <div class="right">
                            <div class="itm">
                                <div>Boosters income</div>
                                <span>{{placement_matches.booster_income}}$</span>
                            </div>
                            <div class="itm">
                                <div>Our income</div>
                                <span>{{placement_matches.our_income}}$</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="li-top">
                        <div class="">
                            <img src="../../../assets/img/orderType/type_3.png">
                            <span>Unrated Games</span>
                        </div>
                        <div class="right">{{unrated_games.sum}}$</div>
                    </div>
                    <div class="li-con">
                        <div class="left">
                            <div class="num red">{{unrated_games.month}}</div>
                            <div class="txt">month</div>
                        </div>
                        <div class="right">
                            <div class="itm">
                                <div>Boosters income</div>
                                <span>{{unrated_games.booster_income}}$</span>
                            </div>
                            <div class="itm">
                                <div>Our income</div>
                                <span>{{unrated_games.our_income}}$</span>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- <li>
                    <div class="li-top">
                        <div class="">
                            <img src="../../../assets/img/orderType/type_4.png">
                            <span>Ranked Wins</span>
                        </div>
                        <div class="right">12$</div>
                    </div>
                    <div class="li-con">
                        <div class="left">
                            <div class="num red">12%</div>
                            <div class="txt">month</div>
                        </div>
                        <div class="right">
                            <div class="itm">
                                <div>Boosters income</div>
                                <span>2254$</span>
                            </div>
                            <div class="itm">
                                <div>Our income</div>
                                <span>2254$</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="li-top">
                        <div class="">
                            <img src="../../../assets/img/orderType/type_5.png">
                            <span>Account Leveling</span>
                        </div>
                        <div class="right">12$</div>
                    </div>
                    <div class="li-con">
                        <div class="left">
                            <div class="num red">12%</div>
                            <div class="txt">month</div>
                        </div>
                        <div class="right">
                            <div class="itm">
                                <div>Boosters income</div>
                                <span>2254$</span>
                            </div>
                            <div class="itm">
                                <div>Our income</div>
                                <span>2254$</span>
                            </div>
                        </div>
                    </div>
                </li> -->

            </ul>

            <div class="con-table">
                <div class="con-top">
                    <div class="left">
                        <div class="num">1</div>
                        <div class="txt">Financial report</div>
                        <div class="btn" @click="report">Download report</div>
                    </div>
                    <div class="right">
                        <div class="search_box">
                            <input v-model="searchTxt" placeholder="Search by keywords" @keyup.enter="goSearch">
                            <div class="icon icon_search" @click="goSearch"></div>
                        </div>
                        <div class="date">
                            <el-date-picker v-model="date" type="date" placeholder="" :disabled-date="disabledDate"
                                size="large" format="DD.MM.YYYY" />
                        </div>


                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style="width: 10%;" class="icon_box">
                                ID
                                <div class="icon icon_down"
                                    :style="{ 'transform': sort_data == 2 ? 'rotateX(180deg)' : '' }"
                                    @click="sort_data = sort_data == 1 ? 2 : 1"></div>
                            </th>
                            <th style="width: 15%;" class="icon_box">
                                Boosters
                                <div class="icon icon_down"
                                    :style="{ 'transform': sort_data == 4 ? 'rotateX(180deg)' : '' }"
                                    @click="sort_data = sort_data == 3 ? 4 : 3"></div>
                            </th>
                            <th style="width: 15%;" class="icon_box">
                                Client
                                <div class="icon icon_down"
                                    :style="{ 'transform': sort_data == 6 ? 'rotateX(180deg)' : '' }"
                                    @click="sort_data = sort_data == 5 ? 6 : 5"></div>
                            </th>
                            <th style="width: 20%;" class="icon_box">
                                Orders type
                                <div class="icon icon_down"
                                    :style="{ 'transform': sort_data == 8 ? 'rotateX(180deg)' : '' }"
                                    @click="sort_data = sort_data == 7 ? 8 : 7"></div>
                            </th>
                            <th style="width: 20%;" class="icon_box">
                                Boosters income
                                <div class="icon icon_down"
                                    :style="{ 'transform': sort_data == 10 ? 'rotateX(180deg)' : '' }"
                                    @click="sort_data = sort_data == 9 ? 10 : 9"></div>
                            </th>
                            <th style="width: 20%;" class="icon_box">
                                Our income
                                <div class="icon icon_down"
                                    :style="{ 'transform': sort_data == 12 ? 'rotateX(180deg)' : '' }"
                                    @click="sort_data = sort_data == 11 ? 12 : 11"></div>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                            <td>{{ item.id }}</td>
                            <td style="text-decoration: underline;cursor: pointer;" @click="goBooster(item)">{{ item.booster }}</td>
                            <td style="text-decoration: underline;">{{ item.client }}</td>
                            <td v-if="item.type == 1">Rank Boosting</td>
                            <td v-if="item.type == 2">Placement Matches</td>
                            <td v-if="item.type == 3">Unrated Games</td>
                            <td v-if="item.type == 4">Ranked Wins</td>
                            <td v-if="item.type == 5">Account Leveling</td>
                            <td>{{ item.booster_income }}</td>
                            <td>{{ item.our_income }}</td>

                        </tr>
                    </tbody>
                </table>

                <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
                    @backPage='backPage'></pagination>
            </div>
        </div>

    </div>
</template>
<script>

import {
    ElMessage, ElLoading
} from 'element-plus'
import Api from '../../../utils/api.js';
import pagination from '../../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            sort_data: '',
            list: [],
            nowPage: 1,
            total: 0,
            pageSize: 11,
            date: '',
            searchTxt: '',
            count: '',
            placement_matches: '',
            rank_boosting: '',
            unrated_games: ''
        }
    },
    watch: {
        sort_data(e) {
            this.getList();
        },
        date(e) {
            this.getList();
        }
    },
    mounted() {
        this.getList();
        this.getInfo();
    },
    methods: {
        goBooster(item){
            this.$router.push({
                path:'/statistic/booster',
                query:{
                    booster_id:item.booster_id

                }
            })
        },
        getInfo() {
            Api.statisticInfo().then(res => {
                if (res.code == 200) {
                    this.count = res.data.count;
                    this.placement_matches = res.data.placement_matches;
                    this.rank_boosting = res.data.rank_boosting;
                    this.unrated_games = res.data.unrated_games;
                } else {
                    ElMessage.error(res.message);

                }
            })
        },
        report() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.statisticOrderReport({
                sort: this.sort_data,
                timestamp: new Date(this.date).getTime() / 1000,
                search: this.searchTxt,
            }).then(res => {
                if (res.code == 200) {
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                    window.open(res.data.url)
                } else {
                    ElMessage.error(res.message);
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        disabledDate(time) {
            return time.getTime() > Date.now();
        },
        getList() {
            let loadingInstance = ElLoading.service({
                background: 'rgba(0, 0, 0, 0.2)',
                lock: true,
            });
            Api.statisticOrder({
                limit: this.pageSize,
                sort: this.sort_data,
                timestamp: new Date(this.date).getTime() / 1000,
                search: this.searchTxt,
                page: this.nowPage
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data.data;
                    this.total = res.data.meta.pagination.total;
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                } else {
                    ElMessage.error(res.message);
                    this.$nextTick(() => {
                        loadingInstance.close();
                    });
                }
            })
        },
        goSearch() {
            this.nowPage = 1;
            this.getList();
        },
        goPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        nextPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        backPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
    }
}
</script>
<style>
.date .el-input__wrapper {
    background-color: transparent !important;
    color: #fff;
    box-shadow: none;
    border: 1px solid var(--input-border);
}

.date .el-input__wrapper.is-focus {
    box-shadow: none;
}

.date .el-input--large .el-input__inner {
    color: var(--input-border) !important;
}

.date .el-icon svg {
    color: var(--submit);
}

.date .el-picker-panel {
    background-color: var(--text-color-light2) !important;
    color: #fff;
}

.date .el-date-picker__header .el-icon svg {
    color: #fff !important;
}

.date .el-picker-panel__icon-btn {
    color: #fff !important;
}

.date .el-date-table td.disabled .el-date-table-cell {
    background-color: transparent !important;
}
</style>

<style scoped>
.box {
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    margin: -20px;
    padding: 20px;
    border-radius: 0;
    overflow-y: scroll;
}

/* .box .con{
    margin: 20px;
} */

.box .top {
    display: block;
    text-align: left;
}

.box .top li {
    display: inline-flex;
    width: calc(33.3% - 50px);
    margin-right: 10px;
    border-radius: 20px;
    background-color: var(--background-light);
    padding: 10px 20px;
    color: var(--text-color);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

}

.box .top li img {
    height: 25px;
    object-fit: cover;
    margin-right: 10px;
}

.box .top li .right {
    background-color: var(--text-color-purple);
    border-radius: 20px;
    padding: 5px;
    color: var(--text-color);
}

.box .type-box {
    margin-top: 10px;
    text-align: left;
}

.box .type-box li {
    width: calc(33.3% - 50px);
    margin-right: 10px;
    border-radius: 20px;
    background-color: var(--background-light);
    padding: 10px 20px;
    color: var(--text-color);
    margin-bottom: 10px;
    color: var(--text-color);
    display: inline-block;
}

.box .type-box li .li-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--img-border);
    padding-bottom: 5px;
}

.box .type-box li .li-top img {
    width: 18px;
    height: 18px;
    object-fit: cover;
    margin-right: 10px;
}

.box .type-box li .li-top .right {
    background-color: var(--text-color-purple);
    border-radius: 20px;
    padding: 5px;
    color: var(--text-color);
}

.box .type-box li .li-con {
    display: flex;
    margin-top: 3px;
    height: 50px;
}

.box .type-box li .li-con .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
    border-right: 1px solid var(--img-border);
}

.box .type-box li .li-con .left .num {
    font-size: 16px;
}

.box .type-box li .li-con .left .num.red {
    color: var(--submit);
}

.box .type-box li .li-con .left .num.green {
    color: var(--text-color-green);
}

.box .type-box li .li-con .left .txt {
    font-size: 12px;
    color: var(--text-color-light0);
}

.box .type-box li .li-con .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
    padding-left: 5px;
}

.box .type-box li .li-con .right .itm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.box .type-box li .li-con .right .itm div {
    color: var(--text-color-light);
}

.box .con-table {
    background-color: var(--background-light);
    border-radius: 20px;
    padding: 20px;
    position: relative;
    padding-bottom: 70px;
}

.box .con-table .con-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.box .con-table .con-top .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color);
}

.box .con-table .con-top .left .num {
    background-color: var(--submit);
    color: var(--text-color);
    padding: 5px 8px;
    border-radius: 50%;
    margin-right: 10px;
}

.box .con-table .con-top .left .txt {
    font-size: 16px;
}

.box .con-table .con-top .left .btn {
    border: 1px solid var(--submit);
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--text-color);
    cursor: pointer;
    margin-left: 10px;
}

.box .con-table .con-top .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box .con-table .con-top .right .search_box,
.date_box {
    display: flex;
    align-items: center;
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 12px 14px;
    margin-right: 20px;
}

.box .con-table .con-top .right .search_box input {
    border-right: 1px solid var(--border);
    width: 200px;
    color: var(--text-color);
}

.box .con-table .con-top .right .search_box .icon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    background-image: url(../../../assets/img/search.png);
    margin-left: 10px;
}

.box .con-table .con-top .right .date_box .date {
    border-right: 1px solid var(--border);
    width: 120px;
    color: var(--text-color);
    height: 15px;
}

.box .con-table .con-top .right .date_box .icon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    background-image: url(../../../assets/img/date.png);
    margin-left: 10px;
    overflow: hidden;
}
</style>
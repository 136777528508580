<template>
	<div class="pagination">
		<div class="bottom">
			<!-- <div class='left-btn' @click="backPage">Back</div>
      <div class="center">
        <div class="center-item" @click="goPage(item)" :class="item==nowPage?'active':''"  v-for="(item,index) in totalPage">{{item}}</div>
      </div>
      <div class="right-btn" @click="nextPage">Next</div> -->
			<el-pagination background layout="prev, pager, next" :page-size="pageSize" :page-count="totalPage"  :current-page="nowPage"
				@current-change="goPage" @prev-click="backPage" @next-click="nextPage" :total="total" />
		</div>

	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	export default {
		props: {
			total: Number,
			pageSize: Number,
			nowPage: Number,
		},
		computed: {
			totalPage() {
				// console.log(this.total)
				if (this.total == 0) {
					return 1
				} else {
					return Math.ceil(this.total / this.pageSize);
				}

			}
		},
		data() {
			return {
				pageList: [],
			}
		},
		created() {

		},
		methods: {
			goPage(e) {
				// console.log(e)
				this.$emit('goPage', e)
			},
			nextPage(e) {
				// console.log(e)
				// if (this.nowPage >= this.totalPage) {
				// 	ElMessage('Already the last page.')
				// 	return
				// } else {
				// 	// this.$emit('nextPage', e)
				// }
			},
			backPage(e) {
				// if (this.nowPage <= 1) {
				// 	ElMessage('Already the first page.')
				// 	return
				// } else {
				// 	// this.$emit('backPage', e)
				// }
			},
		}
	}
</script>

<style lang='less'>
	.bottom {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: calc(100% - 40px);
		min-width: auto;
		height: 48px;
		background-color: #fff;
		border-radius: 16px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
		font-size: 14px;
		overflow: hidden;
		background-color: var(--background-light);

		.el-pagination {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;

			.btn-prev,
			.btn-next {
				background-color: var(--background-light) !important;
				color: var(--text-color-light) !important;


			}
		}
		.el-pagination.is-background .el-pager li.is-active {
			background-color: var(--submit);
			color:var(--text-color);
			border-radius: 10px;
		}
		.el-pagination.is-background .el-pager li {
			background-color: transparent;
				color:var(--text-color-light);
		}

		.el-pagination .btn-next .el-icon,
		.el-pagination .btn-prev .el-icon {
			font-size: 20px !important;
			font-weight: bold !important;
		}

		button,
		li {
			width: 34px !important;
			height: 34px !important;
		}

		.left-btn,
		.right-btn {
			background-color: var(--background);
			border-radius: 5px;
			text-align: center;
			color: var(--font-color);
			line-height: 50px;
			padding: 0 20px;
			height: 50px;
		}

		.center {
			display: flex;

			.center-item {
				background-color: var(--background);
				border-radius: 5px;
				color: var(--font-color);
				width: 50px;
				height: 50px;
				line-height: 50px;
				margin: 0 2px;
			}

			.active {
				color: #fff;
				background-color: var(--primary-color);
			}
		}
	}
</style>
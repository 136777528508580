<template>
	<div class="box">
		<div class="top">
			<ul class='left'>
				<li :class="tab == 1 ? 'active' : ''" @click="goTab(1)">
					<span>New</span>
					<div class="num" v-if="total1>0">{{total1}}</div>
				</li>
				<div class="line"></div>
				<li :class="tab == 2 ? 'active' : ''" @click="goTab(2)">
					<span>In Progress</span>
					<!-- <div class="num">0</div> -->
				</li>
				<div class="line"></div>
				<li :class="tab == 3 ? 'active' : ''" @click="goTab(3)">
					<span>Finished</span>
					<!-- <div class="num">0</div> -->
				</li>

			</ul>
			<div class="right">
				<div class="search_box">
					<input v-model="search" placeholder="Search by keywords" @keyup.enter="goSearch">
					<div class="icon icon_search" @click="goSearch"></div>
				</div>

			</div>
		</div>
		<div class="con-table">
			<table v-if="tab == 1">
				<thead>
					<tr>
						<th style="width: 6%;">Info</th>
						<th style="width: 15%;">Order number</th>
						<!-- <th style="width: 12%;">Server</th> -->
						<th style="width: 15%;">Games</th>
						<!-- <th style="width: 12%;">Region</th> -->
						<th style="width: 15%;">Info</th>
						<th style="width: 15%;">Booster</th>
						<th style="width: 15%;">Approve</th>
						<th style="width: 16%;">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in list1" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
						<td class="icon_box">
							<div class="icon icon_info"></div>
						</td>
						<td>{{ item.number }}</td>
						<!-- <td>{{ item.server }}</td> -->
						<td>{{ item.games }}</td>
						<!-- <td>{{ item.region }}</td> -->
						<td>{{ item.server+'/'+item.shared }}</td>
						<td>
							<el-select v-model="item.booster_name_choose" placeholder="Select" size="small"
								@change="change_booster($event, item, index)">
								<el-option v-for="itm in booster_arr" :key="itm.id" :label="itm.username"
									:value="itm.username" />
							</el-select>
						</td>
						<td class="icon_box">
							<div class="iconBG save" v-if="item.booster_name_choose !=
								item.booster_name" style="background-color: var(--submit);border: 1px solid var(--submit);">
								<div class="icon icon_yes" @click="saveBooster(item)"></div>
							</div>
							
							<div class="iconBG save" v-else>
								<div class="icon icon_yes"></div>
							</div>
							<div class="iconBG reset" v-if="item.booster_name!=null"
								style="background-color: transparent;border: 1px solid var(--submit);">
								<div class="icon icon_reset" @click="resetBooster(item)"></div>
							</div>
							<div class="iconBG reset" v-else>
								<div class="icon icon_reset"></div>
							</div>
						</td>
						<td class="icon_box">
							<div class="icon icon_del" @click="openPop_del(item)"></div>
							<!-- <div class="icon icon_edit"></div>-->
							<div class="icon icon_copy" @click="copy(item)"></div>
							<div class="icon icon_see" @click="openPop_info(item)"></div>
						</td>
					</tr>
				</tbody>
			</table>
			<table v-if="tab == 2">
				<thead>
					<tr>
						<th style="width: 6%;">Info</th>
						<th style="width: 16%;">Order number</th>
						<!-- <th style="width: 17%;">Server</th> -->
						<th style="width: 16%;">Games</th>
						<!-- <th style="width: 12%;">Region</th> -->
						<!-- <th style="width: 10%;">Shared</th> -->
						<th style="width: 17%;">Booster</th>
						<!-- <th style="width: 10%;">Approve</th> -->
						<th style="width: 17%;">Date of Start</th>
						<th style="width: 16%;">Approve</th>
						<th style="width: 19%;">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in list2" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
						<td class="icon_box">
							<div class="icon icon_info"></div>
						</td>
						<td>{{ item.number }}</td>
						<!-- <td>{{ item.server }}</td> -->
						<td>{{ item.games }}</td>
						<!-- <td>{{ item.region }}</td> -->
						<td>{{ item.booster_name }}</td>
						<td v-if="item.start_date">{{ getDate(item.start_date) }}</td>
						<td v-else></td>
						<!-- <td class="icon_box" :class="choose_booster_index == index ? 'submit' : ''">
							<div class="iconBG">
								<div class="icon icon_yes"></div>
							</div>
							<div class="iconBG">
								<div class="icon icon_reset"></div>
							</div>
						</td> -->
						<td class="icon_box">
							<!-- <div class="iconBG save">
								<div class="icon icon_yes" @click="saveBooster(item)"></div>
							</div> -->
							<div class="iconBG reset">
								<div class="icon icon_reset" @click="resetOrder(item)"></div>
							</div>
						</td>
						<td class="icon_box">
							<!-- <div class="icon icon_del"></div>
							<div class="icon icon_edit"></div>-->
							<div class="icon icon_red_warn" v-if="!item.login_username" @click="hint"></div>
								<div class="icon " v-else></div>
							<div class="icon icon_copy" @click="copy(item)"></div>
							<div class="icon icon_see" @click="openPop_info(item)"></div>
							<div class="icon icon_complete" v-if="item.work_status==2" @click="complete(item)"></div>
							<div class="icon" v-else></div>
						</td>
					</tr>
				</tbody>
			</table>
			<table v-if="tab == 3">
				<thead>
					<tr>
						<th style="width: 6%;">Info</th>
						<th style="width: 15%;">Order number</th>
						<!-- <th style="width: 12%;">Server</th> -->
						<th style="width: 15%;">Games</th>
						<!-- <th style="width: 12%;">Region</th> -->
						<!-- <th style="width: 10%;">Shared</th>
						<th style="width: 13%;">Booster</th>
						<th style="width: 10%;">Approve</th>
						<th style="width: 14%;">Action</th> -->
						<th style="width: 15%;">Booster</th>
						<th style="width: 15%;">Date</th>
						<th style="width: 15%;">Status</th>
						<th style="width: 16%;">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in list3" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
						<td class="icon_box">
							<div class="icon icon_info"></div>
						</td>
						<td>{{ item.number }}</td>
						<!-- <td>{{ item.server }}</td> -->
						<td>{{ item.games }}</td>
						<!-- <td>{{ item.region }}</td> -->
						<td>{{ item.booster_name }}</td>
						<td v-if="item.start_date">{{ getDate(item.start_date) }}-{{ getDate(item.end_date) }}</td>
						<td v-else></td>
						<td class="txt_box">
							<!-- <div class="red_txt">
								Hard Decline
								<div class="icon icon_red_warn"></div>
							</div> -->
							<!-- <div class="pink_txt">
								Soft Decline
								<div class="icon icon_pink_warn"></div>
							</div> -->
							<div class="red_txt" v-if="item.deleted_admin_username">
								Deleted
								<div class="icon icon_red_warn"></div>
							</div>
							<div class="green_txt" v-else>
								Finished
							</div>
							<div class="delMsg" v-if="item.deleted_admin_username">
								<div class="tit">Deleted by</div>
								<div class="flex">
									<img :src="item.deleted_admin_avatar" />
									<div class="right">
										<div class="name">{{ item.deleted_admin_username }}</div>
										<div class="identity">Admin</div>
									</div>
								</div>
								<div class="title">
									Reason for declining
								</div>
								<p class="txt">
									{{ item.deleted_comment || 'Not upload' }}
								</p>
							</div>
						</td>
						<td class="icon_box">
							<div class="icon icon_red_warn" v-if="!item.login_username" @click="hint"></div>
								<div class="icon " v-else></div>
							<div class="icon icon_del" v-if="!item.deleted_admin_username" @click="openPop_del(item)"></div>
							<div class="icon" v-else></div>
							<!-- <div class="icon icon_edit"></div> -->
							<div class="icon icon_copy" @click="copy(item)"></div>
							<div class="icon icon_see" @click="openPop_info(item)"></div>
						</td>
					</tr>
				</tbody>
			</table>

		</div>
		<div class="pop-bg" v-if="bg" @click="close()">
			<div class="pop_box" v-if="pop_info" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Order Info
					</div>
					<div class="flex-box">
						<div class="left-con">
							<div class="left-con-item">
								<div class="item">
									<div class="l">
										Promocode status:
									</div>
									<div class="r">{{ info.promo_code_status }}</div>
								</div>
							</div>
							<div class="left-con-item">
								<div class="item">
									<div class="l">
										Client name:
									</div>
									<div class="r">{{ info.client_name }}</div>
								</div>
								<div class="item">
									<div class="l">
										Booster name:
									</div>
									<div class="r">{{ info.booster_name }}</div>
								</div>
								<div class="item">
									<div class="l">
										Admin name:
									</div>
									<div class="r">{{ info.admin_name }}</div>
								</div>
							</div>
							<div class="left-con-item">
								<div class="item">
									<div class="l">
										User name:
									</div>
									<div class="r">{{ info.login_username }}</div>
								</div>
								<div class="item">
									<div class="l">
										Password:
									</div>
									<div class="r">{{ info.login_password }}</div>
								</div>
							</div>
							<div class="left-con-item">
								<div class="item">
									<div class="l">
										Payment status:
									</div>
									<div class="r">
										{{ info.payment_status == 0 ? 'Unaccepted' : info.payment_status == 1 ? 'Ongoing' : 'Completed' }}
									</div>
								</div>
								<div class="item">
									<div class="l">
										Payment system:
									</div>
									<div class="r">{{ info.payment_system }}</div>
								</div>
								<div class="item">
									<div class="l">
										Order status:
									</div>
									<div class="r">
										{{ info.order_status == 0 ? 'Unaccepted' : info.order_status == 1 ? 'Ongoing' : 'Completed' }}
									</div>
								</div>
							</div>
							<div class="left-con-item">
								<div class="item">
									<div class="l">
										Total Amount:
									</div>
									<div class="r">{{ info.total_amount }}</div>
								</div>
							</div>
						</div>
						<div class="right-con">
							<div class="right-con-item">
								<div class="l">Service:</div>
								<div class="r">{{ info.service }}</div>
							</div>
							<div class="right-con-item">
								<div class="l">Order number:</div>
								<div class="r">{{ info.order_number }}</div>
							</div>
							<!-- <div class="right-con-item">
								<div class="l">Region:</div>
								<div class="r">{{ info.region }}</div>
							</div> -->
							<div class="right-con-item">
								<div class="l">Server:</div>
								<div class="r">{{ info.server }}</div>
							</div>
							<div class="right-con-item">
								<div class="l">Number of games:</div>
								<div class="r">{{ info.games_number }}</div>
							</div>
							<div class="right-con-item">
								<div class="l">Date of Start:</div>
								<div class="r">{{ getDate(info.start_date) }}</div>
							</div>
							<!-- <div class="right-con-item">
								<div class="l">Max bid:</div>
								<div class="r">{{info.max_bid}}</div>
							</div>
							<div class="right-con-item">
								<div class="l">Min bid:</div>
								<div class="r">{{info.min_bid}}</div>
							</div> -->
							<div class="right-con-item">
								<div class="l">Solo/Party:</div>
								<div class="r">{{ info.shared }}</div>
							</div>
							<div class="right-con-item">
								<div class="l">Platform:</div>
								<div class="r">{{ info.platform }}</div>
							</div>
							<div class="right-con-item">
								<div class="l">Additional features:</div>
								<div class="r">
									<div v-for="(item, index) in info.additional_features" :key="index">
										{{ item }}
									</div>
								</div>
							</div>
							<div class="right-con-item">
								<div class="l">Promocode:</div>
								<div class="r">{{ info.promo_code }}</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="pop_box" v-if="pop_del" @click.stop>
				<div class="icon_close" @click="close"></div>
				<div class="pop">
					<div class="title">
						Delete Order
					</div>
					<div class="text-gray left">
						Are you sure you want to delete the order?
					</div>
					<div class="inp-box">
						<textarea v-model="comment"></textarea>
					</div>
					<div class="button-box">
						<button class="cancel" @click="close">No</button>
						<button class="submit" @click="del">Yes</button>
					</div>
				</div>

			</div>
		</div>

		<pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
			@backPage='backPage'></pagination>
	</div>
</template>

<script>
import Api from '../../../utils/api.js';
import pagination from '../../common/pagination.vue';
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
export default {
	components: {
		pagination
	},
	data() {
		return {
			search: '',
			tab: 1,
			pageSize: 8,
			nowPage: 1,
			total: 0,
			total1: 0,
			list1: [],
			list2: [],
			list3: [],
			booster_arr: [{
				name: '1',
				id: 0
			},
			{
				name: '2',
				id: 1
			},
			],
			choose_booster_index: Number,
			bg: false,
			pop_info: false,
			pop_del: false,
			info: '',
			id: '',
			comment: ''
		}

	},
	watch: {
		tab(e) {
			
		}
	},
	created() {

	},
	mounted() {
		let table = document.getElementsByClassName('con-table')[0];
		this.pageSize = parseInt((table.clientHeight - 50) / 50);
		
		this.getBoosterList();
		if(this.$route.query.number){
			this.search = this.$route.query.number;
			this.tab = this.$route.query.status;
			this.getOrderUnratedGames();
		}else{
			this.getOrderUnratedGames();
		}
	},
	methods: {
		hint(){
			ElMessage.warning("Missing account and password, please contact the customer.")
		},
		goTab(e){
			this.tab = e;
			this.nowPage = 1;
			this.search = '';
			this.getOrderUnratedGames();
		},
		//add
		resetOrder(item){
			ElMessageBox.confirm(
				'Are you sure you want to move the ongoing order to a new order？',
				'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning',
				customClass:'mydeactivate',
			}
			)
			.then((e) => {
				console.log(e)
				console.log(item)
				if (e == 'confirm') {
						Api.resetOrder({
							order_id:item.id
						}).then(res => {
							if (res.code == 200) {
								this.tab = 1;
							} else {
								ElMessage.error(res.message)
							}
						})
				}
			})
			.catch(() => {

			})
		},
		complete(item){
			Api.orderComplete({
				order_id:item.id
			}).then(res => {
				if (res.code == 200) {
					this.getOrderUnratedGames();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		copy(item) {
			navigator.clipboard.writeText(item.text);
			ElMessage.success("Copied successfully")
		},
		saveBooster(item) {
			if(!item.booster_id_choose){
				ElMessage('Please select booster');
				return;
			}
			Api.orderBoosterChoose({
				order_id: item.id,
				booster_id: item.booster_id_choose
			}).then(res => {
				if (res) {
					if (res.code == 200) {
						this.getOrderUnratedGames();
					} else {
						ElMessage.error(res.message)
					}
				}
			})
		},
		resetBooster(item) {
			ElMessageBox.confirm(
				'Are you sure you want to move the ongoing order to a new order？',
				'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning',
				customClass: 'mydeactivate',
			}
			)
				.then((e) => {
					// console.log(e)
					// console.log(item)
					if (e == 'confirm') {
						Api.resetOrder({
							order_id: item.id
						}).then(res => {
							if (res.code == 200) {
								this.getOrderUnratedGames();
							} else {
								ElMessage.error(res.message)
							}
						})
					}
				})
				.catch(() => {

				})
		},
		openPop_del(item) {
			this.bg = true;
			this.pop_del = true;
			this.id = item.id;
		},
		del() {
			Api.orderDelete({
				order_id: this.id,
				deleted_comment: this.comment
			}).then(res => {
				if (res.code == 200) {
					this.close();
					this.getOrderUnratedGames();
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getBoosterList() {
			Api.orderBooster().then(res => {
				if (res.code == 200) {
					this.booster_arr = res.data;
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		getDate(e) {
			const date = new Date(e ? e * 1000 : '');
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = date.getHours();
			let minute = date.getMinutes();
			month = month > 9 ? month : '0' + month;
			day = day > 9 ? day : '0' + day;
			hour = hour > 9 ? hour : '0' + hour;
			minute = minute > 9 ? minute : '0' + minute;
			return `${day}/${month}/${year}`
		},
		close() {
			this.bg = false;
			this.pop_info = false;
			this.info = '';
			this.id = '';
			this.pop_del = false;
			this.comment = '';
		},
		openPop_info(item) {
			Api.orderInfo({
				order_id: item.id,
				order_type: 3
			}).then(res => {
				if (res.code == 200) {
					this.info = res.data;
					this.bg = true;
					this.pop_info = true;
				} else {
					ElMessage.error(res.message)
				}
			})
		},


		goSearch() {
			this.getOrderUnratedGames();
		},
		change_booster(e, item, index) {
			item.booster_name_choose = e;
			item.booster_id_choose = this.booster_arr.find(item => item.username == e).id;
		},
		getOrderUnratedGames() {
			Api.orderUnratedGames({
				limit: this.pageSize,
				search: this.search,
				type: Number(this.tab)
			}).then(res => {
				if (res.code == 200) {
					if (this.tab == 1) {
						this.list1 = res.data.data;
						this.total1 = res.data.meta.pagination.total;
					} else if (this.tab == 2) {
						this.list2 = res.data.data;
					} else if (this.tab == 3) {
						this.list3 = res.data.data;
					}
					for (let i in this.list1) {
						this.list1[i].booster_name_choose = this.list1[i].booster_name;
						this.list1[i].booster_id_choose = this.list1[i].booster_id;
					}
					this.total = res.data.meta.pagination.total;
				} else {
					ElMessage.error(res.message)
				}
			})
		},
		goPage(e) {
			this.nowPage = e
			this.getOrderUnratedGames();
			console.log('nowPage:', this.nowPage)
		},
		nextPage(e) {
			this.nowPage = e
			this.getOrderUnratedGames();
			console.log('nowPage:', this.nowPage)
		},
		backPage(e) {
			this.nowPage = e
			this.getOrderUnratedGames();
			console.log('nowPage:', this.nowPage)
		},
	}
}
</script>

<style scoped>
.box {
	width: 100%;
	height: 100%;
	position: relative;
}

.con-table {
	height: calc(100% - 106px);
}
.con-table table{
	overflow:auto;
}

</style>
<style>
.mydeactivate.el-message-box{
	background: var(--Grey-700, #463F41);
	border: none;
}
.mydeactivate .el-message-box__title{
	color:#fff;
}
.mydeactivate .el-button{
 --el-button-text-color: #333;
    --el-button-bg-color: #fff;
    --el-button-border-color: #fff;
}
.mydeactivate  .el-button--primary{
    --el-button-text-color: #fff;
    --el-button-bg-color: #333;
    --el-button-border-color: #333;
}
.mydeactivate .el-message-box__btns  .el-button:focus{
    border:none;
    outline:none;
    background:#fff;
    color:#333;
}
.mydeactivate .el-message-box__btns  .el-button:hover{
    background:#fff;
    color:#333;
}
.mydeactivate .el-message-box__btns .el-button--primary:focus{
    border:none;
    outline:none;
    background:#333;
    color:#fff;
}
.mydeactivate .el-message-box__btns .el-button--primary:hover{
    background:#333;
    color:#fff;
}
.mydeactivate .el-message-box__headerbtn:hover .el-message-box__close{
	color:#909399;
}
</style>
export default {
	adminPanel: 'Admin panel',
	connect: 'connect',
	logOut: 'Log out',
	login: 'Login',
	password: 'Password',
	signIn: 'Sign in',
	thisFieldIsRequired: 'This field is required',
	loginSMS1: 'An SMS with a code has been sent to your number',
	loginSMS2: 'please enter it.',
	enter: 'Enter',
	code: 'code',
	userOnline: 'User online',
	adminChat: 'Admin chat',
	menu: [{
			name: 'User Accounts',
			id: 0,
			message: 0,
			path: '/index',

			children: [{
				name: 'Deleted accounts',
				id: 0,
				message: 0,
				path: '/deleted-account',

			}, {
				name: 'Verification documents',
				id: 1,
				message: 0,
				path: '/verification-documents',

			}, ]
		},
		// {
		//   name: 'Subscription',
		//   id: 1,
		//   message: 0,
		//   path:'',

		// }, {
		//   name: 'List of administrators',
		//   id: 2,
		//   message: 0,
		//   path:'',

		// },
		{
			name: 'Orders',
			id: 3,
			message: 0,
			path: '/orders',

			children: [{
				name: 'Request for help',
				id: 0,
				message: 0,
				path: '/request-for-help',

			}]
		},
		{
			name: 'Services',
			id: 4,
			message: 0,
			path: '/services',

			children: [{
				name: 'Services on moderation',
				id: 0,
				message: 0,
				path: '/service-on-moderation',

			}]
		},
		// {
		//   name: 'Content page',
		//   id: 5,
		//   message: 0,
		//   path:'',

		// },
		// {
		//   name: 'Promotion',
		//   id: 6,
		//   message: 0,
		//   path:'',

		// },
		// {
		//   name: 'Purchased promotion',
		//   id: 7,
		//   message: 0,
		//   path:'',

		// }, {
		//   name: 'Refund',
		//   id: 8,
		//   message: 0,
		//   path:'',

		// }, {
		//   name: 'blog',
		//   id: 9,
		//   message: 0,
		//   path:'',

		// }, {
		//   name: 'support',
		//   id: 10,
		//   message: 0,
		//   path:'',

		//   children: [{
		//     name: 'Feedback for support',
		//     id: 0,
		//     message: 0,
		//     path:'',

		//   }]
		// }, {
		//   name: 'Data management',
		//   id: 11,
		//   message: 0,
		//   path:'',

		// }, {
		//   name: 'FAQ',
		//   id: 12,
		//   message: 0,
		//   path:'',

		// },
		{
			name: 'Districts',
			id: 13,
			message: 0,
			path: '/districts',

		},
		// {
		//         name: 'Complaints',
		//         id: 14,
		//         message: 0,
		//         path:'',

		//         children: [{
		//           name: "Types of complaints",
		//           id: 0,
		//           message: 0,
		//           path:'',

		//         }, {
		//           name: "All complaints from users",
		//           id: 1,
		//           message: 0,
		//           path:'',

		//         }]
		//       },
		//       {
		//         name: "Organization structure",
		//         id: 15,
		//         message: 0,
		//         path:'',

		//       }



	],
}
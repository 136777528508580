import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

import store from '../store'

import login from '../components/pages/login/login.vue'
import reset_email from '../components/pages/login/reset_email.vue'
import reset_password from '../components/pages/login/reset_password.vue'
import layout from '../components/common/layout.vue'
import index from '../components/pages/dashboard/index.vue'
import deletedUser from '../components/pages/dashboard/deletedUser.vue'
import administrators from '../components/pages/dashboard/administrators.vue'
import booster from '../components/pages/dashboard/booster.vue'
import job from '../components/pages/dashboard/job.vue'
import rank_boosting from '../components/pages/orders/rank_boosting.vue'
import account_leveling from '../components/pages/orders/account_leveling.vue'
import placement_matches from '../components/pages/orders/placement_matches.vue'
import ranked_wins from '../components/pages/orders/ranked_wins.vue'
import unrated_games from '../components/pages/orders/unrated_games.vue'
import news from '../components/pages/news.vue'
import faq from '../components/pages/faq.vue'
import privacyPolicy from '../components/pages/privacyPolicy.vue'
import termsConditions from '../components/pages/termsConditions.vue'
import fine from '../components/pages/fine.vue'
import rule from '../components/pages/rule.vue'
import calculator from '../components/pages/calculator.vue'
import chat from '../components/pages/adminChat.vue'
import support from '../components/pages/support.vue'
import supportChat from '../components/pages/chat.vue'
import promocode from '../components/pages/promocode.vue'
import accessSettings from '../components/pages/accessSettings.vue'
import queueSettings from '../components/pages/queueSettings.vue'
import homePage from '../components/pages/homePage.vue'
import about from '../components/pages/about.vue'
import financial_reports from '../components/pages/statistic/financial_reports.vue'
import boosterInfo from '../components/pages/statistic/booster.vue'
import current_balance from '../components/pages/statistic/current_balance.vue'



const routes = [{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/reset_email',
		name: 'reset_email',
		component: reset_email
	},
	{
		path: '/reset_password',
		name: 'reset_password',
		component: reset_password
	},
	{
		path: '/',
		component: layout,
		children: [{
				path: '',
				redirect: '/dashboard/index'
			},
			{
				name: 'dashboard/user',
				path: '/dashboard/index',
				component: index,
			},
			{
				name: '/dashboard/deletedUser',
				path: '/dashboard/deletedUser',
				component: deletedUser,
			},
			{
				name: 'dashboard/booster',
				path: '/dashboard/booster',
				component: booster,
			},
			{
				name: 'dashboard/job',
				path: '/dashboard/job',
				component: job,
			},
			{
				name: 'dashboard/administrators',
				path: '/dashboard/administrators',
				component: administrators,
			},
			{
				name: 'order/rank_boosting',
				path: 'order/rank_boosting',
				component: rank_boosting,
			},
			{
				name: 'order/account_leveling',
				path: 'order/account_leveling',
				component: account_leveling,
			},
			{
				name: 'order/placement_matches',
				path: 'order/placement_matches',
				component: placement_matches,
			},
			{
				name: 'order/ranked_wins',
				path: 'order/ranked_wins',
				component: ranked_wins,
			},
			{
				name: 'order/unrated_games',
				path: 'order/unrated_games',
				component: unrated_games,
			},
			{
				name: 'statistic/financial_reports',
				path: 'statistic/financial_reports',
				component: financial_reports,
			},
			{
				name: 'statistic/booster',
				path: 'statistic/booster',
				component: boosterInfo,
			},
			{
				name: 'current_balance',
				path: 'statistic/boosters/current_balance',
				component: current_balance,
			},
			{
				name: 'announcement_dashboard',
				path: 'announcement_dashboard',
				component: news,
			},
			{
				name: 'faq',
				path: 'faq',
				component: faq,
			},
			{
				name: 'privacy_policy',
				path: 'privacy_policy',
				component: privacyPolicy,
			},
			{
				name: 'terms_conditions',
				path: 'terms_conditions',
				component: termsConditions,
			},
			{
				name: 'goboost_fine',
				path: 'goboost_fine',
				component: fine,
			},
			{
				name: 'goboost_rules',
				path: 'goboost_rules',
				component: rule,
			},
			{
				name: 'calculator',
				path: 'calculator',
				component: calculator,
			}, {
				name: 'chat',
				path: 'chat',
				component: chat,
			}, {
				name: 'support',
				path: 'support',
				component: support,
			},
			{
				name: 'support_chat',
				path: 'support_chat',
				component: supportChat,
			},
			{
				name: 'Promocode',
				path: 'promocode',
				component: promocode,
			},
			{
				name: 'queue_settings',
				path: 'queue_settings',
				component: queueSettings,
			},
			{
				name: 'access_settings',
				path: 'access_settings',
				component: accessSettings,
			},
			{
				name: 'home_page',
				path: 'home_page',
				component: homePage,
			},
			{
				name: 'about',
				path: 'about',
				component: about,
			}
		],
	}
]
const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router;
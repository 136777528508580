import router from './index.js';
import Api from '../utils/api.js'
import {
	ElMessage
} from 'element-plus'

const logOut = function (e) {
	Api.chatLogout().then(res => {
		if (res.code == 200) {
			localStorage.clear();
		}
	}).catch(err => {

	})
}

const getChatToken = function () {
	Api.chatToken().then(res => {
		if (res.code == 200) {
			localStorage.setItem('chat_token', res.data.token)
			localStorage.setItem('chat_userID', res.data.userID)
			localStorage.setItem('chat_expireTimeSeconds', res.data.expireTimeSeconds)
			// getLoginStatus();
		} else {
			ElMessage.error(res.message)
		}
	}).catch(err => {
		ElMessage.error(err.message);
	})
}
const authMe = function () {
		Api.authMe().then(res => {
			if (res.code == 200) {
				localStorage.setItem('unread', res.data.unread);
			} else {
				ElMessage.error(res.message)
			}
		}).catch(err => {
			ElMessage.error(err.message)
		})
	}

	router.beforeEach((to, from, next) => {
		// if (from.path == '/chat' || from.path == "/support_chat") {
		// 	// authMe();
		// 	next();
		// }
		if (to.path == '/login' || to.path == "/reset_email" || to.path == "/reset_password") {
			if (localStorage.getItem('token')) {
				logOut();
			} else {
				localStorage.clear();
			}
			next();

		} else {
			const nowTime = Math.round(new Date() / 1000);
			const timer = localStorage.getItem('expires_in')
			if (!localStorage.getItem('token') || timer < nowTime) {
				console.log('token expires');
				router.push('/login');
			} else {
				const chat_timer = nowTime + Number(localStorage.getItem('chat_expireTimeSeconds'));
				if (!localStorage.getItem('chat_token') || localStorage.getItem('chat_token') == undefined ||
					chat_timer <= nowTime || !localStorage.getItem('chat_expireTimeSeconds')) {
					getChatToken();
					
				}
					next()
				

			}
		}


	})
export default {
 adminPanel:'Панель администратора',
 	connect:'connect',
	logOut:'Выйти',
 	login:'авторизоваться',
 	password:'Пароль',
 	signIn:'регистр',
 	thisFieldIsRequired:'Это поле обязательно к заполнению',
	loginSMS1:'На ваш номер отправлено СМС с кодом',
	loginSMS2:'пожалуйста, введите это.',
	enter:'Входить',
	code:'код',
	userOnline:'Пользователь онлайн',
	adminChat:'Административный чат',
	menu: [{
			name: 'Учетные пользователей',
			id: 0,
			message: 0,
			path: '/index',
	
			children: [{
				name: 'Удаленные аккаунты',
				id: 0,
				message: 0,
				path: '/deleted-account',
	
			}, {
				name: 'Поверочные документы',
				id: 1,
				message: 0,
				path: '/verification-documents',
	
			}, ]
		},
		// {
		//   name: 'Subscription',
		//   id: 1,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'List of administrators',
		//   id: 2,
		//   message: 0,
		//   path:'',
	
		// },
		{
			name: 'Заказы',
			id: 3,
			message: 0,
			path: '/orders',
	
			children: [{
				name: 'Просьба о помощи',
				id: 0,
				message: 0,
				path: '/request-for-help',
	
			}]
		},
		{
			name: 'Услуги',
			id: 4,
			message: 0,
			path: '/services',
	
			children: [{
				name: 'Услуги по модерации',
				id: 0,
				message: 0,
				path: '/service-on-moderation',
	
			}]
		},
		// {
		//   name: 'Content page',
		//   id: 5,
		//   message: 0,
		//   path:'',
	
		// },
		// {
		//   name: 'Promotion',
		//   id: 6,
		//   message: 0,
		//   path:'',
	
		// },
		// {
		//   name: 'Purchased promotion',
		//   id: 7,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'Refund',
		//   id: 8,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'blog',
		//   id: 9,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'support',
		//   id: 10,
		//   message: 0,
		//   path:'',
	
		//   children: [{
		//     name: 'Feedback for support',
		//     id: 0,
		//     message: 0,
		//     path:'',
	
		//   }]
		// }, {
		//   name: 'Data management',
		//   id: 11,
		//   message: 0,
		//   path:'',
	
		// }, {
		//   name: 'FAQ',
		//   id: 12,
		//   message: 0,
		//   path:'',
	
		// },
		{
			name: 'Районы',
			id: 13,
			message: 0,
			path: '/districts',
	
		},
		// {
		//         name: 'Complaints',
		//         id: 14,
		//         message: 0,
		//         path:'',
	
		//         children: [{
		//           name: "Types of complaints",
		//           id: 0,
		//           message: 0,
		//           path:'',
	
		//         }, {
		//           name: "All complaints from users",
		//           id: 1,
		//           message: 0,
		//           path:'',
	
		//         }]
		//       },
		//       {
		//         name: "Organization structure",
		//         id: 15,
		//         message: 0,
		//         path:'',
	
		//       }
	
	
	
	]
	
}
import { createI18n } from 'vue-i18n';
import EN from './en.js';
import TR from './tr.js';
import RU from './ru.js';

const message = {
  En: {
    ...EN,
  }, 
  Tr: {
    ...TR,
  }, 
  Ru: {
    ...RU,
  },
}
const i18n = createI18n({
  locale: localStorage.getItem('lang')||'En', 
  legacy: false, 
  globalInjection: true, 
  messages: message,
})

export default i18n;
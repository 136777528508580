<template>
	<div id="app">
		<router-view />
	</div>

</template>
<script>
	const debounce = (fn, delay) => {

		let timer = null;

		return function() {

			let context = this;

			let args = arguments;

			clearTimeout(timer);

			timer = setTimeout(function() {

				fn.apply(context, args);

			}, delay);

		}

	}

	// 解决ERROR ResizeObserver loop completed with undelivered notifications.

	//问题的

	const _ResizeObserver = window.ResizeObserver;

	window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

		constructor(callback) {

			callback = debounce(callback, 16);

			super(callback);

		}

	}

	export default {
		name: 'App',
		data() {
			return {}
		},
		created() {}
	}
</script>

<style src="../src/assets/css/global.css"></style>
<style src="../src/assets/css/root.css"></style>
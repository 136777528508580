<template>
	<div class="navs">
		<div class="logo_box" style="cursor: pointer;" v-if="$route.path == '/calculator'" @click="goIndex">
			<img src="../../assets/img/menu/logo.png" />
		</div>
		<div class="item" style="cursor: pointer;"
			:style="{ 'margin-left': $route.path == '/calculator' ? '-100px' : '20px' }">
			<div class="txt">{{ menu }}</div>
		</div>
		<!-- <div class='item'>
			<div class="centerButton" :style="{ 'margin-left': onlineNumber > 0 ? '-20px' : '10px' }" @click="goChat()">
				<div class="icon"></div>
				<div class="txt-black">{{ $t('adminChat') }}</div>
			</div>
		</div> -->
		<div class="item">
			<div class="bell">
				<div @click="openPop">
					<div class="bell_icon"></div>
					<div class="redNum" v-if="unread>0"></div>
				</div>
				<div class="bell_pop" v-if="bellPop == true">
					<div class="bell_item">
						<div class="icon icon_greenUser"></div>
						<!-- <div class="icon icon_greeOrder"></div>
                <div class="icon icon_greenChat"></div>
                <div class="icon icon_redUser"></div> -->
						<div class="right">
							<div class="top_txt">New user added</div>
							<div class="bottom_txt">12:13 / 19.04.2023</div>
						</div>
					</div>
					<div class="bell_item">
						<div class="icon icon_greeOrder"></div>
						<div class="right">
							<div class="top_txt">New user added</div>
							<div class="bottom_txt">12:13 / 19.04.2023</div>
						</div>
					</div>
					<div class="bell_item">
						<div class="icon icon_greenChat"></div>
						<div class="right">
							<div class="top_txt">New user added</div>
							<div class="bottom_txt">12:13 / 19.04.2023</div>
						</div>
					</div>
					<div class="bell_item">
						<div class="icon icon_redUser"></div>
						<div class="right">
							<div class="top_txt">New user added</div>
							<div class="bottom_txt">12:13 / 19.04.2023</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="bell">
				<div>
					<div class="message_icon"></div>
				</div>
			</div> -->
			<!-- <div class="pinkButton">
				<div class="txt-black">ADD NEW</div>
				<div class="icon_add"></div>
			</div> -->
			<el-select class="sel" v-model="lang">
				<el-option value="EN">EN</el-option>
				<!-- <el-option value="RU">RU</el-option> -->
			</el-select>
			<div class="button" @click="logOut">Log out</div>
		</div>
		<div class="pop-bg" v-if="bg" @click="cancel">
			<div class="pop_box" v-if="pop" @click.stop>
				<div class="icon_close" @click="cancel"></div>
				<div class="pop">
					<div class="title">Log out</div>
					<div class="text-gray left">Are you sure you want to log out？</div>
					<div class="button-box">
						<button class="cancel" @click="cancel">Cancel</button>
						<button class="submit" @click="yes">Yes</button>
					</div>
				</div>
			</div>

		</div>
		<Notification @getUnread="getUnread"></Notification>
	</div>
</template> 

<script>
import Api from '../../utils/api.js';
import Notification from './notification.vue';
import {
	ElMessage,
	ElMessageBox
} from 'element-plus'
export default {
	props: {
		page: '',
	},
	components: {
		Notification
	},
	data() {
		return {
			lang: 'EN',
			menu: 'DASHBOARD/USER',
			languageArr: [{
				value: 'Ru',
				id: 0
			}, {
				value: 'En',
				id: 0
			}, {
				value: 'Tr',
				id: 0
			},],
			language: 'Ru',
			locationArr: [{
				value: 'Turkey',
				id: 0
			}, {
				value: 'English',
				id: 0
			}, {
				value: 'Russian',
				id: 0
			}],
			location: 'Turkey',
			bellPop: false,
			onlineArr: [],
			onlineNumber: 0,
			bg: false,
			pop: false,
			unread:0,
		}
	},
	watch: {
		$route(to, from) {
			if (to.name) {
				this.menu = to.name.replace('_', ' ').toUpperCase()
			}

		}
	},
	created() {
		if (this.$route.name) {
			this.menu = this.$route.name.replace('_', ' ').toUpperCase()
		}

		this.language = localStorage.getItem('lang') || 'En';
		if (this.page != 'login') {
			// this.authMe();
			this.getChatOnline()
			this.unread = localStorage.getItem('unread')
		}

	},
	methods: {
		//add
		
		getUnread(e){
			this.unread = e;
		},
		authMe(){
			Api.authMe().then(res => {
				if (res.code == 200) {
					localStorage.setItem('unread',res.data.unread);
				} else {
					ElMessage.error(res.message)
				}
			}).catch(err => {
				ElMessage.error(err.message)
			})
		},
		getChatOnline() {
			return;
			Api.chatOnline().then(res => {
				if (res.code == 200) {
					this.onlineArr = res.data.users_avatar;
					this.onlineNumber = res.data.number
				} else {
					ElMessage.error(res.message)
				}
			}).catch(err => {
				ElMessage.error(err.message)
			})
		},
		goIndex() {
			this.$router.push({
				path: '/'
			})
		},
		languageChange() {
			localStorage.setItem('lang', this.language);
			location.reload();
		},
		goChat() {
			this.$router.push({
				path: '/chat'
			})
		},
		openPop() {
			this.unread = 0
			this.$router.push({
				path: '/chat'
			})
			return;
			this.bellPop = !this.bellPop
		},
		cancel() {
			this.bg = false;
			this.pop = false;

		},
		yes() {
			this.bg = false;
			this.pop = false;
			this.$router.push({
				path: '/login'
			})
			// Api.logout().then(res => {
			// 	if (res) {
			// 		if (res.code == 200) {
			// 			this.bg = false;
			// 			this.pop = false;
			// 			this.$router.push({
			// 				path: '/login'
			// 			})
			// 		} else {

			// 		}
			// 	}
			// })
		},
		logOut() {
			this.bg = true;
			this.pop = true;
			return;
			ElMessageBox.confirm(
				'Please confirm to log out？',
				'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning',
			}
			)
				.then((e) => {
					if (e == 'confirm') {
						this.$OpenIM.logout()
						.then(() => {
							// Successful logout
							console.log('Successful logout=========== adminnav')
						})
						.catch(({ errCode, errMsg }) => {
							// Call failed
							console.log('failed logout=========== adminnav')
							console.log(errCode)
							console.log(errMsg)
						});
						this.$router.push({
							path: '/login'
						})
					}
				})
				.catch(() => {

				})
		}
	}
}
</script>

<style lang='less'>
.navs {
	width: 100%;
	height: 100px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 18px;
	color: #fff;
	/*  min-width: 1220px; */
	/* // background-color: #fff; */

	/* // font-weight: bold; */
	.logo_box {
		>img {
			height: 46px;
			margin: 30px auto 20px 20px;

		}
	}

	.item {
		display: flex;
		flex-direction: row;
		align-items: center;

		.txt {
			font-size: 24px;
			margin-left: 20px;
		}

		.grayTxt {
			color: #5A6F81;
			font-size: 16px;
			margin-right: 10px
		}

		.avatarBox {

			>img,
			.num {
				width: 37px;
				height: 37px;
				border-radius: 50%;
				border: 1px solid var(--primary-color-light);

			}

			>img:nth-child(2) {
				position: relative;
				left: -10%
			}

			>img:nth-child(3) {
				position: relative;
				left: -20%
			}

			.num {
				position: relative;
				left: -30%;
				background-color: var(--primary-color);
				font-size: 12px;
				line-height: 37px;
				display: inline-block;
			}
		}


		.centerButton {
			cursor: pointer;
			display: flex;
			align-items: center;
			border: 1px solid var(--submit);
			color: #fff;
			text-align: center;
			padding: 10px 20px;
			border-radius: 20px;
			font-size: 14px;
			margin-left: -20px;

			.icon {
				width: 16px;
				height: 16px;
				background-image: url(../../assets/img/chat-white.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 85%;
				margin-right: 2px;
			}

			.txt-black {
				color: var(--regular-font);
				font-size: 14px;
				font-weight: 400px;

			}
		}



		.select {
			border: none;
			color: #fff;
			/* // width: 41px; */
			margin-right: 10px;
			background-color: transparent;
			/* // -webkit-appearance: none; */

			/* // outline: none; */
		}



		.el-select {
			border: none;
			/* // width: 41px; */
			/* // margin-right: 10px; */
			width: 100px;
		}

		.el-select .el-select__wrapper {
			border: none !important;
		}

		.el-select .el-select__wrapper,
		.is-hovering {
			border: none !important;
			box-shadow: none;
		}

		.el-input__inner {
			border-radius: 50%;
			background-color: rgba(225, 225, 225, 0.3);
			color: #fff;
			width: 60px;
			height: 60px;
			line-height: 60px;
			text-align: center !important;
		}

		.el-input__suffix-inner>:first-child {
			margin-left: 5px;
		}

		.el-select .el-input .el-select__caret {
			color: #fff;
			font-size: 12px;
		}

		.el-input__wrapper {
			border: none;
			box-shadow: none;

			.el-input__inner {
				border: none;
				box-shadow: none;
				width: 25px;
				text-align: right;
			}
		}

		.el-select .el-input.is-focus .el-input__wrapper {
			box-shadow: none !important;
		}

		.el-select .el-input__wrapper.is-focus {
			box-shadow: none !important;
		}


		.bell {
			position: relative;

			.bell_icon {
				width: 26px;
				height: 26px;
				background-image: url(../../assets/img/bell0.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 80%;
				cursor: pointer;
				margin: 0 10px;
			}

			.message_icon {
				width: 26px;
				height: 26px;
				background-image: url(../../assets/img/message.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 80%;
				cursor: pointer;
				margin: 0 10px;
			}

			.redNum {
				background-color: #FF3B30;
				color: #fff;
				font-size: 10px;
				border-radius: 50%;
				padding: 2px;
				position: absolute;
				right: 10px;
				top: -3px;
				width: 5px;
				height: 5px;
				line-height: 10px;
				text-align: center;
				font-weight: 200;
			}

			.bell_pop {
				background-color: var(--background);
				border-radius: 10px;
				position: absolute;
				top: 30px;
				right: 0px;
				padding: 10px 26px;
				/* box-shadow: 0px 0px 10px #f1f1f1; */
				z-index: 999;

				.bell_item {
					display: flex;
					margin: 14px auto;

					.icon {
						width: 24px;
						height: 24px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: 100%;
						margin-right: 10px;
						/* // margin-top:5px; */
					}



					.right {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						color: #434343;

						.top_txt {
							font-size: 16px;
							color: #fff;
							font-weight: 200;
						}

						.bottom_txt {
							font-size: 12px;
							font-weight: 50;
							color: var(--light-font);
							text-align: left;
							/* // opacity: 0.8; */
						}
					}
				}
			}
		}

		.pinkButton {
			border-radius: 200px;
			background-color: var(--submit);
			color: var(--text-color);
			padding: 14px 32px;
			display: flex;
			margin-left: 10px;

			.icon_add {
				width: 16px;
				height: 16px;
				background-image: url(../../assets/img/add.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 85%;
				margin-right: 2px;
				margin-left: 10px;
			}
		}

		.button {
			color: var(--text-color);
			border: 1px solid var(--border);
			width: 95px;
			height: 34px;
			line-height: 34px;
			text-align: center;
			border-radius: 6px;
			font-size: 16px;
			cursor: pointer;
		}
	}
}
</style>
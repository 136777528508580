<template>
    <div class="box">
        <div class="top">
            <ul class='left' style="cursor: pointer;">
                <li :class="tab == 1 ? 'active' : ''" @click="tab = 1">
                    <span>All</span>
                    <div class="num" v-if="total1 > 0">{{ total1 }}</div>
                </li>
                <div class="line"></div>
                <li :class="tab == 2 ? 'active' : ''" @click="tab = 2">
                    <span>Users</span>
                    <div class="num" v-if="total2 > 0">{{ total2 }}</div>
                </li>
                <div class="line"></div>
                <li :class="tab == 3 ? 'active' : ''" @click="tab = 3">
                    <span>Boosters</span>
                    <div class="num" v-if="total3 > 0">{{ total3 }}</div>
                </li>
                <div class="line"></div>
                <li :class="tab == 4 ? 'active' : ''" @click="tab = 4">
                    <span>Coaches</span>
                    <div class="num" v-if="total4 > 0">{{ total4 }}</div>
                </li>
                <div class="line"></div>
                <li :class="tab == 5 ? 'active' : ''" @click="tab = 5">
                    <span>Admin</span>
                    <div class="num" v-if="total5 > 0">{{ total5 }}</div>
                </li>

            </ul>

            <div class="right">
                <div class="search_box">
                    <input v-model="search" placeholder="Search by keywords" @keyup.enter="goSearch">
                    <div class="icon icon_search" @click="goSearch"></div>
                </div>
            </div>
        </div>
        <div class="con-table">
            <table>
                <thead>
                    <tr>
                        <th style="width: 5%;">Info</th>
                        <th style="width: 15%;">Name</th>
                        <th style="width: 10%;">Role</th>
                        <th style="width: 11%;">Email</th>
                        <th style="width: 10%;">Country</th>
                        <th style="width: 15%;">Reason</th>
                        <th style="width: 12%;">Date </th>
                        <th style="width: 12%;">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'" :style="{'background-color': seeIndex==index?'#454885':''}">
                        <td :style="{'padding-bottom':seeIndex==index?'60px':'10px'}" class="icon_box">
                            <div class="icon icon_down" @click="see(item,index)"></div>
                        </td>
                        <td :style="{'padding-bottom':seeIndex==index?'60px':'10px'}"
                        style="color: #fff;">{{ item.username }}</td>
                        <td :style="{'padding-bottom':seeIndex==index?'60px':'10px'}" >{{ item.role_name }}</td>
                        <td :style="{'padding-bottom':seeIndex==index?'60px':'10px'}" >{{ item.email }}</td>
                        <td :style="{'padding-bottom':seeIndex==index?'60px':'10px'}" >{{ item.country_name }}</td>
                        <td :style="{'padding-bottom':seeIndex==index?'60px':'10px'}" >{{ item.reason }}</td>
                        <td :style="{'padding-bottom':seeIndex==index?'60px':'10px'}" >{{ getDate(item.deleted_at) }}</td>
                        <td class="icon_box" :style="{'padding-bottom':seeIndex==index?'60px':'10px'}" >
                            <div class="icon icon_see" ></div>
                            <div class="icon icon_resume" @click="openPop(item)"></div>
                        </td>
                        <div class="see" v-if="seeIndex==index">
                            <div class="txt" >
                                Balance at the time of deletion:
                                <span class="fff">{{item.role!=1?item.balance:0}}$</span>
                            </div>
                            <button @click="report(item)">Transactions report</button>
                        </div>
                        <div class="see"></div>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="pop-bg" v-if="bg" @click="close()">

            <div class="pop_box" v-if="pop_restore" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Restore user?
                    </div>
                    <div class="pop_flex">
                        <div class="text-gray left">
                           Reason:
                        </div>
                        <div class="right">{{ reason }}</div>
                    </div>

                    <div class="button-box">
                        <button class="cancel" @click="close">No</button>
                        <button class="submit" @click="restore">Restore</button>
                    </div>
                </div>

            </div>
        </div>
        <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
            @backPage='backPage'></pagination>
    </div>
</template>

<script>
import {
    ElMessage
} from 'element-plus'
import Api from '../../../utils/api.js';
import pagination from '../../common/pagination.vue';
export default {
    components: {
        pagination
    },
    data() {
        return {
            seeIndex:null,
            search: '',
            pageSize: 8,
            nowPage: 1,
            total:0,
            total1: 0,
            total2: 0,
            total3: 0,
            total4: 0,
            total5: 0,
            tab: 1,
            list: [],
            bg: false,
            pop_restore: false,
            id: "",
            reason:'',
            role:''

        }

    },
    watch: {
        tab(e) {
            this.nowPage = 1;
            this.seeIndex = null;
            this.getList();

        }
    },
    created() {
    },
    mounted() {
        let table = document.getElementsByClassName('con-table')[0];
        this.pageSize = parseInt((table.clientHeight - 50) / 50);
        this.getList();
        this.getCount();
    },
    methods: {
        see(item,index){
            if(item.role==3){
                return;
            }
            if(this.seeIndex==index){
                this.seeIndex = null;
            }else{
                this.seeIndex = index;
            }
        },
        report(item){
            Api.deleteUserReport({
                user_id:item.id,
                role:item.role
            }).then(res => {
                if (res.code == 200) {
                    window.open(res.data.url)
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        restore() {
            Api.deleteUserRestore({
                user_id:this.id,
                role:this.role
            }).then(res => {
                if (res.code == 200) {
                    this.getList();
                    this.close();
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        getCount() {
            Api.deleteUserCount({
            }).then(res => {
                if (res.code == 200) {
                    this.total1 = res.data.all;
                    this.total2 = res.data.user;
                    this.total3 = res.data.booster;
                    this.total4 = res.data.coach;
                    this.total5 = res.data.admin;
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        openPop(item) {
            this.pop_restore = true;
            this.bg = true;
            this.id = item.id;
            this.reason = item.reason;
            this.role = item.role;
        },
        close() {
            this.pop_restore = false;
            this.bg = false;
            this.id = '';
            this.reason = '';
            this.role = '';
        },
        getDate(e) {
            const date = new Date(e ? e * 1000 : '');
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${day}/${month}/${year}`
        },
        goSearch() {
            this.nowPage = 1;
            this.getList();
        },
        getList() {
            Api.deleteUserList({
                limit: this.pageSize,
                search: this.search,
                type: this.tab,
                page: this.nowPage
            }).then(res => {
                if (res) {
                    if (res.code == 200) {
                        this.list = res.data.data;
                        this.total = res.data.meta.pagination.total;
                    } else {
                        ElMessage.error(res.message)
                    }
                }

            })
        },
        goPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        nextPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        backPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
    }
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 100%;
    position: relative;
}

.con-table {
    height: calc(100% - 106px);
}

.top .left li {
    width: auto;
}

.con-table table tr .icon_box.submit .icon {
    cursor: no-drop !important;
}
.con-table table tr td {
    padding-bottom: 10px;
    padding-top: 10px;
}
.con-table table tr{
    position: relative;
}
.con-table table tr .see{
    position: absolute;
    bottom: 10px;
    left: 6%;
    display: flex;
    line-height: normal;
}
.con-table table tr .txt{
   color: rgba(255,255,255,0.5);
   background-color:#5665A3;
   border-radius: 8px;
   padding: 12px 24px;
   margin-right: 10px;
}
.con-table table tr .fff{
   color: #fff;

}
.con-table table tr .see button{
    background-color: var(--submit);
	border: 1px solid var(--submit);
    border-radius: 200px;
    padding: 12px 24px;
    color: #fff;
}
.pop .pop_flex{
    display: flex;
    color: #fff;
    flex-direction: row;
    justify-items: flex-start;
}
.pop{
    width: 350px;
}
.pop .pop_flex .left{
    width: auto;
    margin-right: 10px;
}
.pop .pop_flex .right{
    text-align: left;
    line-height: 20px;
}
</style>
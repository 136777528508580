<template>
	<view class="cropper-templeted" >
		<view class="pop_cropper">
			<view class="title">Edit photo</view>
			<vueCropper class="cropper" ref="cropper" :img="option.img" :outputSize="1" :outputType="option.outputType"
				:canScale="true" :autoCrop="true" :autoCropWidth="200" :autoCropHeight="200" :fixed="true" :full="true">
			</vueCropper>
			<view class="pop-btn-box">
				<button class="cancel" @click="close">Cancel</button>
				<button class="submit" @click="save">Save photo</button>
			</view>
		</view>


	</view>
</template>

<script>
	// import 'vue-cropper/dist/index.css'
	// import  VueCropper   from "vue-cropper";
	export default {
		props: {
			option: Object,
		},
		data() {
			return {
			}
		},
		created() {
			// console.log(this.option)
		},
		methods:{
			close(){
				this.$emit('savePhoto','')
			},
			base64toFile(base64Data, fileName) {
			    var arr = base64Data.split(',');
			    var mime = arr[0].match(/:(.*?);/)[1];
			    var bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			    while(n--){
			        u8arr[n] = bstr.charCodeAt(n);
			    }
			    return new File([u8arr], fileName, {type:mime});
			},
			save(){
				this.$refs.cropper.getCropData((data) => {
				    console.log(data)
				    var fileName = 'image.png';
				    var file = this.base64toFile(data, fileName);
					console.log(file);
					this.$emit('savePhoto',file)
				    // this.upImage(file)
				    // this.model = true
				    // this.modelSrc = data
				})
			}
		}
	}
</script>

<style lang='less'>
	.cropper-templeted {
		width: 100vw;
		height: 100vh;
		position: fixed !important;
		left: 0 !important;
		top: 0 !important;
		bottom: 0 !important;
		right: 0 !important;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.2);
		.pop_cropper{
			background-color: var(--background-light2);
			border-radius: 10px;
			z-index: 9999;
			padding: 40px;
			position: relative;
			max-height: 80%;
			min-width: 400px;
			width:40%;
			overflow-y: scroll;
			.title {
				font-size: 24px;
				color: var(--text-color);
				height: 30px;
				line-height: 30px;
				text-align: center;
				display: block;
				margin-bottom:10px;
			}
			.cropper{
				/* width:350px; */
				height:350px;
			}
			.pop-btn-box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 20px;
			
				.cancel,
				.submit {
					width: 48%;
					border-radius: 30px;
					padding: 10px 0;
					color: #fff;
					font-size: 14px;
					line-height: 30px;
					text-align: center;
					border: 1px solid var(--submit);
				}
			
				.cancel {
					// background-color: var(--cancel-btn);
				}
			
				.submit {
					background-color: var(--submit);
				}
			}
			
		}
		
	}
</style>